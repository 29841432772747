import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const CapitalGains = () => {
    return (
        <PageLayout header={"CAPITAL GAINS"}>
            <div className="flex flex-col justify-between align-middle layout__header w-[100%]" >
                <div className="my-2 text-theme-800">
                    <h1 className={`my-2 ${h1}`}>Capital asset</h1>
                    <p className="text-theme-800">Any profit or gain arising from the sale or transfer of a capital asset is computed under this head. Capital asset refers to property of any kind held by an assessee, whether or not connected with his business or professional, excluding the following:</p>
                    <ul className="list-disc my-3">
                        <li>Any stock-in-trade, consumable stores or raw materials held for the purposes of business or profession;</li>
                        <li>Personal effects, namely, movable property (including apparel and furniture held for personal use by assessee or any other member of his/her family dependent on him/her,but excludes jewellery, archaeological collections, drawings, paintings, sculptures or any work of art</li>
                        <li>Agricultural land in India, subject to certain conditions;</li>
                        <li>Certain specified government bonds.</li>
                    </ul>
                    <h1 className={`my-2 ${h1}`}>Short-term and long term capital assets</h1>
                    <p className="text-theme-800">For the purposes or taxation, capital assets are classified as long-term or short-term, depending upon the period of holding of such assets.</p>
                    <p className="my-2 text-theme-800">A long-term capital asset means a capital asset held by an individual for more than 36 months immediately preceding its date of transfer. However, the following are treated as long-term capital assets, if held for more than 12 months:</p>
                    <ul className="list-disc my-3">
                        <li>Shares held in a company;</li>
                        <li>Other securities listed in a recognized stock exchange in India ;</li>
                        <li>Units of the Unit Trust of India or specified mutual funds.</li>
                    </ul>
                    <p className="text-theme-800">(Sec 2(29A) and 2(42A) of the Income-tax Act, 1961)</p>

                    <h1 className={`my-2 ${h1}`}>Short-term and long-term capital gains</h1>
                    <p className="mb-2 text-theme-800">The distinction between short-term and long-term capital assets is important, since this distinction determines whether the capital gain should be taxed as short-term capital gain or as long-term capital gain and consequently the tax rate that applies to such type of capital gains.</p>
                    <p className="text-theme-800">AShort-term capital gains are included within normal income, and taxed in accordance, with the progressive slab rates of tax for individuals. Long-term capital gains are generally taxable at the rate of 20%, though this rate could be reduced to 15% in case of capital gains arising from transfer to certain long-term capital assests (sec 2(29B), 2(42B) and 112 of the Income-tax Act, 1961)</p>

                    <p className="my-2 text-theme-800">However, short term capital gains arising on transfer of equity shares in a company or a unit of an equity oriented fund (on satisfaction of prescribed conditions) are taxable at the rate of 15%.</p>

                    <p className="my-2 text-theme-800">Further, any income arising from the transfer of a long term capital asset, being an equity share in a company or a unit of an equity oriented fund (subject to conditions being satisfied) is exempt.</p>
                    <h1 className={`my-2 ${h1}`}>Computation of capital gains</h1>
                    <p className="text-theme-800">In order to compute capital gains, expenditure incurred in relation to the sale or transfer as well as the cost of acquisition and improvement of the capital asset are reduced from the full value of the consideration arising on the transfer of the capital asset.</p>
                    <p className="my-1 text-theme-800">No deduction is allowed in computing the capital gain in respect of any sum paid on account of securities transaction tax.</p>
                    <p className="text-theme-800">In case an employee transfers shares, warrants or debentures under a gift, or an irrevocable trust, which were allotted to him under an Employee Stock Option Plan (“ESOP”), that meets certain guidelines laid down by the Government, the fair market value on the date of transfer is regarded as the full value of consideration</p>
                    <p className="my-2 text-theme-800">Where the sale consideration for transfer of land or building (or both) is less than the value adopted or assessed for levy of stamp duty in respect of such transfer, then the value so adopted as assessed for stamp duty purposes shall be deemed to be the sale consideration for computing the capital gains. However, if the taxpayer disputes the value so adopted, the Revenue Officer may refer the matter to the valuation officer under the Act. If the valuation officer revises the stamp duty value, the capital gains shall be computed with reference to the revised value provided such revised value is lower than the stamp duty value.</p>
                    <p className="my-2 text-theme-800">The cost of acquisition for certain modes of acquisition (gifts, inheritance, etc) is generally the cost of acquisition to the previous owner(s).</p>
                    <p className="my-1 text-theme-800">Cost of acquisition of bonus shares is considered as nil.</p>
                    <p className="my-1 text-theme-800">In the case of the long-term capital assets, if the capital asset was acquired prior to 1 April 1981, cost of acquisition would be substituted by the fair market value as on 1 April 1981 and the indexation would be available with reference to the value as on 1 April 1981.</p>
                    <p className="my-2 text-theme-800">In case of long-term capital assests, the costs of acquisition and improvement can be adjusted upwards by applying an inflation index number, which has been specified for every year, since 1981 (sec 48 and 50C of the Income-tax Act, 1961).</p>
                    <p className="my-2 text-theme-800">Consequent to ESOP being brought within the purview of fringe benefit tax, the value of the specified securities or sweat equity taken into account to compute fringe benefits will be the acquisition cost of the specified securities or shares to derive the capital gains on the transfer of such securities or shares.</p>
                    <h1 className={`my-2 ${h1}`}>Special provision for non-residents</h1>
                    <p className="text-theme-800">Capital gain arising to a non-resident on transfer of shares and debentures of an Indian company acquired for foreign currency is computed in the following manner</p>
                    <ul className="list-disc my-2">
                        <li>Convert the full value of consideration, in the original currency of acquisition of shares or debentures, using the exchange rate on the date of transfer.</li>
                        <li>Convert the cost of acquisition in the original currency of acquisition of the shares or debentures at the exchange rate on the date of acquisition of shares.</li>
                        <li>Convert the expense incurred in connection with the transfer, in the original currency of acquisition of the shares or debentures at the exchange rate on the date of incurring the expense.</li>
                        <li>Reduce the cost of acquisition and expense incurred in connection with the transfer, as computed above, from the full value of consideration, to arrive at the capital gains in foreign currency.</li>
                        <li>Convert such capital gain calculated in foreign currency, into Indian rupees, using the exchange rate on the date of transfer.</li>
                    </ul>
                    <p className="text-theme-800">When the above conversion option is applicable to a non-resident in the case of transfer of shares and debentures of an Indian company qualifying as long-term capital assets, indexation provisions do not apply (sec 48 of the Income-tax Act, 1961).</p>
                    <h1 className={`my-2 ${h1}`}>Exemptions</h1>
                    <p className="text-theme-800">Long-term capital gains are exempt, if such gains or the sale proceeds of long-term capital assests are invested in certain specified assets, subject to satisfaction of certain conditions. The relevant exemptions are discussed in detail below.</p>
                    <strong className="text-xs my-4">Sale proceeds of residential property reinvested in residential property</strong>
                    <p className="text-theme-800">Capital gains arising from transfer of a residential property, being buildings or land appurtenant thereto, the income of which is chargeable under the head income from house property, are eligible for an exemption subject to fulfilment of the following conditions:</p>
                    <ul className="my-3 list-disc">
                        <li>The residential property is a long-term capital asset.</li>
                        <li>The individual either:</li>
                    </ul>
                    <p className="text-theme-800">a) Purchases a residential property within a period of one year before or two years after the date of transfer;</p>
                    <p className="text-theme-800">b) constructs a residential property within a period of three years after the date of transfer.</p>
                    <p className="my-3 text-theme-800">The extent of exemption available from capital gains is the lower of the following:</p>
                    <ul className="my-3 list-disc">
                        <li>The cost of new residential property purchased or constructed ;.</li>
                        <li>The amount of consideration.</li>
                    </ul>
                    <p className="my-2 text-theme-800">If the new residential property is sold/transferred within a period of three years from the date of purchase or construction, the amount of capital gains arising therefrom, together with the amount of capital gain in the year of subsequent sale/transfer is taxed in the year in which property is sold.</p>
                    <p className="text-theme-800">If the net consideration is not appropriated towards purchase or construction or the new residential house, it should be deposited in any branch of a public sector bank or institutions in accordance with the Capital Gains Account Scheme, before the due date of filing the personal income tax return.</p>
                    <p className="my-2 text-theme-800">The amount so invested should be utilized within two years from date of transfer of the original capital asset for purchasing, or within three years of such date of transfer, for construction of a new residential house. The amount invested if not utilized for the purchase of construction, within three years from the date of transfer of the original capital asset, is taxed as long-term capital gain (sec 54 of the Income Tax Act, 1961)</p>
                    <div className="my-2 text-theme-800">
                        <strong className="text-xs">Sale proceeds of long-term capital assets reinvested in specified bonds</strong>
                        <p className="my-2 text-theme-800">Capital gains arising from the transfer of any long-term capital asset, are eligible for an exemption subject to fulfillment of the following conditions:</p>
                        <ul className="my-3 list-disc">
                            <li>The individual has, within six months from the date of transfer of the asset, invested whole or any part of the capital gains in specified long-term assets. These assets are defined to include any bond redeemable after three years issued on or after 1 April 2006 by the National Highways Authority of India and by the Rural Electrification Corporation Limited. Further, from 1 April 2007, a ceiling of INR 5,000,000 has been stipulated for investments in “long-term specified bonds” made during any financial year and the requirement of notifying such bonds in the Official Gazette has been dispensed with.
                                The Exemption available from capital gains is:</li>
                            <li>The amount of capital gain, if the cost of the specified long-term asset is not less than the amount of capital gain;</li>
                            <li>If the cost of the specified long-term asset is less than the amount of capital gain, then the amount of exemption is equal to the amount invested in the specified asset.</li>
                        </ul>
                        <p className="text-theme-800">There is a restriction on transferring or converting the specified asset into money (including in the form of any loan/advance against the security of the specified asset) within a period of three years from the date of its acquisition. If so transferred or converted, capital gains arising from transfer of original asset that had not been charged to tax shall be taxed as long-term capital gains in the year in which such specified asset is transferred or converted (sec 54EC of the Income Tax Act, 1961).</p>
                        <div className="my-2 text-theme-800">
                            <strong className="text-xs my-3">Sale proceeds of long-term capital assets reinvested in residential property</strong>
                            <p className="text-theme-800">Capital gains arising from transfer of long-term capital asset, not being a residential house, are eligible for an exemption, subject to fulfillment of the following conditions:</p>
                            <ul className="my-3 list-disc">
                                <li>The individual either:</li>
                            </ul>
                            <p className="text-theme-800">a) Purchases a residential property within a period of one year before or two years after the due date of transfer;</p>
                            <p className="text-theme-800">b) Constructs a residential property within a period of three years after the date of transfer.</p>
                            <p className="my-2 text-theme-800">The exemption available from capital gains is :</p>
                            <ul className="my-3 list-disc">
                                <li>The amount of net consideration, if the cost of new residential house purchased or constructed, is not less than the amount of net consideration;</li>
                                <li>If the cost of new residential house purchased or constructed is less than the amount of net consideration, the amount of net consideration pro-rated against the cost of the new residential house purchased or constructed out of the net consideration.
                                    Net consideration means full value of the consideration arising or transfer of capital asset after deduction of any expenditure incurred in connection with the transfer.
                                    However, the above exemption may be withdrawn in the following circumstances and taxed accordingly:</li>
                                    <li>If the individual sells or transfers the new residential house within three years of its purchase or construction;</li>
                                    <li>If the individual purchases, within a period of two years of the transfer of the original asset, or constructs within a period of three years of transfer of such asset, a residential house (whose income is taxable under Income from house property) other than the new residential house.</li>

                            </ul>
                            <p className="text-theme-800">In the aforesaid two cases, the amount of capital gains arising from transfer of original asset, which was not taxed, will be deemed to be long-term capital gains and taxed in the year in which such new residential house is transferred, or another residential house (other than the new house) is purchased or constructed.</p>
                            <p className="text-theme-800">If the net consideration is not appropriated towards purchase or construction of the new residential house, it should be invested in a deposit account in any branch of a public sector bank or institution in accordance with the Capital Gains Account Scheme before the due date of filing the personal income tax return.</p>
                            <p className="text-theme-800">The amount so invested should be utilized within two years from date of transfer of the original capital asset for purchasing, or within three years of such date of transfer, for construction of a residential house. The amount invested, if not utilized within three years from the date of transfer of the original capital asset, is taxed as long-term capital gain (sec 45 to 55 of the Income-tax Act, 1961).</p>
                        </div>
                    </div>
                </div></div>
        </PageLayout>
    )
}

export default CapitalGains
