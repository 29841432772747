import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import { spinner } from '../assets';
import { queriesTemplateId, serviceId, publicKey } from '../lib/constants';

const Queries = () => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    organization: "",
    officeAddress: "",
    city: "",
    emailAddress: "",
    telephoneNumber: "",
    mobile: "",
    other: "",
    subject: "",
    query: ""

  })


  const notify = () => {
    toast.info("Your message has been received :)", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await emailjs
      .sendForm(
        serviceId,
        queriesTemplateId,
        formRef.current,
        publicKey
      )
      .then(

        (result) => {
          if (result.status === 200) {
            notify()
            setFormData({
              name: "",
              designation: "",
              organization: "",
              officeAddress: "",
              city: "",
              emailAddress: "",
              telephoneNumber: "",
              mobile: "",
              other: "",
              subject: "",
              query: ""
            });
            setLoading(false)
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-white py-20 ">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-6 layout__header">
            <h1 className="h2 text-theme-800">Query Sheet</h1>
          </div>

          {/* Form */}
          <div className="mx-auto ">
            <form className="flex flex-col justify-center align-middle  mb- w-[100%]" onSubmit={handleSubmit} ref={formRef}>
              <div className="flex flex-row flex-wrap  mb- w-[100%]">
                <div className="appointment__form-group px-3">

                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"

                  >
                    Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="name"
                    value={formData.name}
                    type="text"
                    className="form-input text-gray-950 w-full"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                  >
                    Designation <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="designation"
                    value={formData.designation}
                    type="text"
                    className="form-input text-gray-950 w-full "
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-row flex-wrap  mb- w-[100%]">
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"

                  >
                    Organization
                  </label>
                  <input
                    name="organization"
                    value={formData.organization}
                    type="text"
                    className="form-input text-gray-950 w-full"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                  >
                    Office Address
                  </label>
                  <input
                    name="officeAddress"
                    value={formData.officeAddress}
                    type="text"
                    className="form-input text-gray-950 w-full "
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-row flex-wrap  mb- w-[100%]">
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"

                  >
                    City <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="city"
                    value={formData.city}
                    type="text"
                    className="form-input text-gray-950 w-full"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                  >
                    E-mail Address <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="emailAddress"
                    value={formData.emailAddress}
                    type="text"
                    className="form-input text-gray-950 w-full "
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-row flex-wrap  mb- w-[100%]">
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"

                  >
                    Telephone No.
                  </label>
                  <input
                    name="telephoneNumber"
                    value={formData.telephoneNumber}
                    type="text"
                    className="form-input text-gray-950 w-full"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                  >
                    Mobile <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="mobile"
                    value={formData.mobile}
                    type="text"
                    className="form-input text-gray-950 w-full "
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-row flex-wrap  mb- w-[100%]">
                <div className="appointment__form-group flex flex-col   px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"

                  >
                    Other professional Updates <span className="text-red-600">*</span>
                  </label>
                  <div className="flex flex-row">
                    <div className="px-3 flex flex-row justify-evenly w-[20%]">
                      <label htmlFor="yes" className="block text-theme-400 text-sm font-medium mb-1">Yes</label>
                      <input
                        name="other"
                        value="yes"
                        type="radio"

                        required
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="px-3 flex flex-row justify-evenly w-[20%]">
                      <label htmlFor="yes" className="block text-theme-400 text-sm font-medium mb-1">No</label>
                      <input
                        name="other"
                        value="no"
                        type="radio"
                        required
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="appointment__form-group px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                  >
                    Subject Of Query <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="subject"
                    value={formData.subject}
                    type="text"
                    className="form-input text-gray-950 w-full "
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-row flex-wrap  mb- w-[100%]">
                <div className="w-[100%] px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"

                  >
                    Query <span className="text-red-600">*</span>
                  </label>
                  <textarea name="query"
                    value={formData.query}
                    placeholder='Your Query'
                    onChange={handleInputChange}
                    className="w-full text-gray-950 appearance-none rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 placeholder-theme-400"
                    cols="20" rows="5"></textarea>
                </div>
              </div>

              <div className="flex flex-wrap  mt-6">
                <div className="px-3 flex flex-col justify-center align-middle">
                  <button type='submit' className="btn text-white bg-theme-600 hover:bg-theme-800 w-full">
                    Submit
                  </button>
                  {
                    loading && (
                      <div className='flex justify-center'>
                        <img src={spinner} alt="Loader" width="100px" height="100px" />
                      </div>

                    )
                  }
                </div>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}


export default Queries
