import React from "react"
import "./css/style.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Header from "./components/header"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { URLs } from "./lib/constants"

import {
  Home,
  AboutUs,
  Footer,
  OurServices,
  Career,
  ClientsPage,
  Queries,
} from "./components/index"

import {
  Overview,
  ProhibitedSectors,
  DirectAndIndirect,
  AuditingOverview,
  InternalAudit,
  ServiceTaxAudit,
  TransferPricingAudit,
  TransferPricingFAQs,
  DomesticTransferPricing,
  CompanyFormationOverview,
  CompanyFormationCheckList,
  CompanyFormationFAQs,
  CompanyFormationProcedure,
  BusinessTaxationOverview,
  DirectTaxation,
  IndirectTaxation,
  GST,
  CapitalGains,
  ExpatriatesTaxableIncome,
  TrendsInIndia,
  StatutoryRequirements,
  TaxDueDiligence,
  ForeignCompanyRegistration,
  AccountOutSourcing,
  StartBusinessOverview,
} from "./components/FDI/index"
import Banner from "./components/banner"

const Wrapper = ({ children, addPadding }) => {
  return (
    <>
      <Header />
      <div className={addPadding && "pt-28 md:pt-20 px-4 md:px-0"}>{children}</div>
      <Footer className="bg-theme-800" />
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Wrapper>
              <Home />
            </Wrapper>
          }
        />
        <Route
          path={URLs.ABOUT_US}
          element={
            <Wrapper addPadding={true}>
              <AboutUs fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.SERVICES}
          element={
            <Wrapper addPadding={true}>
              <OurServices fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.CAREER}
          element={
            <Wrapper addPadding={true}>
              <Career fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.CLIENTS}
          element={
            <Wrapper addPadding={true}>
              <ClientsPage fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.QUERIES}
          element={
            <Wrapper addPadding={true}>
              <Queries fullScreen={true} />
            </Wrapper>
          }
        />
        {/* <Route
          path={URLs.NEWS}
          element={
            <Wrapper addPadding={true}>
              <NewsPage fullScreen={true} />
            </Wrapper>
          }
        /> */}
        <Route
          path={URLs.FDI_OVERVIEW}
          element={
            <Wrapper addPadding={true}>
              <Overview fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_PROHIBITED_SECTORS}
          element={
            <Wrapper addPadding={true}>
              <ProhibitedSectors fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_DIRECT_AND_INDIRECT_INVESTMENT}
          element={
            <Wrapper addPadding={true}>
              <DirectAndIndirect fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_AUDITING_OVERVIEW}
          element={
            <Wrapper addPadding={true}>
              <AuditingOverview fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_INTERNAL_AUDIT}
          element={
            <Wrapper addPadding={true}>
              <InternalAudit fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_SERVICE_TAX_AUDIT}
          element={
            <Wrapper addPadding={true}>
              <ServiceTaxAudit fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_TRANSFER_PRICING_AUDIT}
          element={
            <Wrapper addPadding={true}>
              <TransferPricingAudit fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_TRANSFER_PRICING_FAQS}
          element={
            <Wrapper addPadding={true}>
              <TransferPricingFAQs fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_DOMESTIC_TRANSFER_PRICING}
          element={
            <Wrapper addPadding={true}>
              <DomesticTransferPricing fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_COMPANY_FORMATION_OVERVIEW}
          element={
            <Wrapper addPadding={true}>
              <CompanyFormationOverview fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_COMPANY_FORMATION_CHECKLIST}
          element={
            <Wrapper addPadding={true}>
              <CompanyFormationCheckList fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_COMPANY_FORMATION_FAQS}
          element={
            <Wrapper addPadding={true}>
              <CompanyFormationFAQs fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_COMPANY_FORMATION_PROCEDURE}
          element={
            <Wrapper addPadding={true}>
              <CompanyFormationProcedure fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_BUSINESS_TAXATION_OVERVIEW}
          element={
            <Wrapper addPadding={true}>
              <BusinessTaxationOverview fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_BUSINESS_TAXATION_DIRECT_TAXATION}
          element={
            <Wrapper addPadding={true}>
              <DirectTaxation fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_BUSINESS_TAXATION_INDIRECT_TAXATION}
          element={
            <Wrapper addPadding={true}>
              <IndirectTaxation fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_BUSINESS_TAXATION_GST}
          element={
            <Wrapper addPadding={true}>
              <GST fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_BUSINESS_TAXATION_CAPITAL_GAINS}
          element={
            <Wrapper addPadding={true}>
              <CapitalGains fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_TAXABLE_INCOME_OF_EXPATRIATES}
          element={
            <Wrapper addPadding={true}>
              <ExpatriatesTaxableIncome fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_TRENDS_IN_INDIA}
          element={
            <Wrapper addPadding={true}>
              <TrendsInIndia fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_STATUTORY_REQUIREMENT}
          element={
            <Wrapper addPadding={true}>
              <StatutoryRequirements fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_TAX_DUE_DILIGENCE}
          element={
            <Wrapper addPadding={true}>
              <TaxDueDiligence fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_STARTING_BUSINESS_IN_INDIA}
          element={
            <Wrapper addPadding={true}>
              <StartBusinessOverview fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_ACCOUNT_OUTSOURCING}
          element={
            <Wrapper addPadding={true}>
              <AccountOutSourcing fullScreen={true} />
            </Wrapper>
          }
        />
        <Route
          path={URLs.FDI_REGISTRATION_OF_FOREIGN_COMPANIES}
          element={
            <Wrapper addPadding={true}>
              <ForeignCompanyRegistration fullScreen={true} />
            </Wrapper>
          }
        />
      </Routes>
      <Banner />
    </BrowserRouter>
  )
}

export default App
