import React from "react"
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"

const IndirectTaxation = () => {
  return (
    <PageLayout header={"INDIRECT TAXATION"}>
      <div className="flex flex-col justify-between align-middle layout__header w-[100%]">
        <div className="my-2">
          <h1 className={`my-2 ${h1}`}>INDIRECT TAXATION IN INDIA</h1>
          <p className="text-theme-800">Service tax is a central tax, which has been imposed on certain services and is the latest addition to the genus of indirect taxes like customs and central excise duty. India, a developing country, was somewhat slow in discovering the potential of this kind of indirect taxation for enhancement of revenue collection and it was the Finance Act 1994 that first introduced the service tax provisions through its Chapter V. Service Tax is collected by Central Excise Department.</p>
          <h1 className={`my-2 ${h1}`}>Some of our services include :</h1>
          <ul className="list-disc my-3">
            <li>
              Compiling and calculating the net service tax on output services after
              taking benefit of Cenvat Credits.
            </li>
            <li>Compiling the data of Cenvat Credits for service tax.</li>
            <li>Preparing & Filing of Service tax Returns.</li>
            <li>Advising on the issues relating to Service tax.</li>
            <li>Consultancy on the maintenance of prescribed records.</li>
            <li>
              Tax Planning as regards the minimization of Service Tax Liability.
            </li>
          </ul>
          <h1 className={`my-2 ${h1}`}>VALUE ADDED TAX (VAT)</h1>
          <p className="text-theme-800">“Value Added Tax” (VAT) is a tax on value addition and a multi point tax, which is levied at every stage of sale. It is collected at the stage of manufacture/resale and contemplates rebating of tax paid on inputs and purchases</p>
          <p className="my-2 text-theme-800">Some of our VAT related services include :</p>
          <ul className="list-disc my-3">
            <li>Rendering assistance in registration under VAT</li>
            <li>Assistance in claiming input tax credit</li>
            <li>Assistance in furnishing tax returns and claiming refunds</li>
            <li>Advice on the legal aspects of VAT</li>
            <li>
              Rendering advice on the wide range of issues in relation to tax
              invoices and retails invoices
            </li>
            <li>Internal Audit and Compliance Reviews</li>
            <li>Helping with audit of accounts necessary for a registered dealer</li>
          </ul>
        </div>
      </div>
    </PageLayout>
  )
}

export default IndirectTaxation
