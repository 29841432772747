import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const DomesticTransferPricing = () => {
  return (
    <PageLayout header={'DOMESTIC TRANSFER PRICING'}>
        <div className="flex flex-col justify-between layout__header align-middle">
        <div className="flex flex-col justify-between align-middle">
        <h1 className={`my-2 ${h1}`}>Introduction</h1>
        <p className="text-theme-800">Assessments from financial year 2012-13 would require application of transfer pricing rules for domestic transactions with related parties. Following is the overview of the latest developments in Domestic Transfer Pricing regulations.</p>
<strong className='my-3 text-theme-800'>Specified Domestic Transactions</strong>
<p className="text-theme-800">Section 92BA provides the meaning of Specified Domestic Transactions to which various other provisions relating to transfer pricing will apply. These include the following:</p>
<div className="my-3">
    <p className="text-theme-800">a) Any expenditure in respect of which payment has been made or is to be made to a person referred to in section 40A(2)(b);</p>
    <p className="text-theme-800">b) Any transaction referred to in section 80A;</p>
    <p className="text-theme-800">c) Any transfer of goods or services referred to in section 80IA(8);</p>
    <p className="text-theme-800">d) Any business transacted between the assessee and other person as referred to in section 80- IA(10);</p>
    <p className="text-theme-800">e) Any transaction, referred to in any other section under Chapter VI-A or section 10AA or the provisions of sub-section (8) or sub-section (10) of section 80-IA are applicable; or</p>
    <p className="text-theme-800">f)Any other transaction as may be prescribed,</p>
</div>
<p className="text-theme-800">And where the aggregate of such transaction entered into by the assessee in the previous year exceeds a sum of two hundred million Indian rupees.</p>
<div className="my-3">
<h1 className={`my-2 text-theme-800 ${h1}`}>Threshold exemption</h1>
<p className="text-theme-800">The Finance Act, 2012 had defined the materiality threshold for the application of the transfer pricing provisions to domestic related party transactions as Rs. fifty million which has increased to Rs. 200 million w.e.f. 01.04.2016. Therefore, where the aggregate value of specified domestic transactions does not exceed Rs. 200 million , no compliance requirements would be necessary such as maintenance of transfer pricing documentation or filling of accountants’ report. Though, it would be necessary to ensure at all times as per the applicable sections that the pricing would have to be on the basis of market value and not influenced by the relationship as between the related parties.</p>
</div>
<h1 className={`mt-2 text-theme-800 ${h1}`}>Consequential amendments</h1>
<strong className='mb-2 text-theme-800'>Related party transactions</strong>
<p className="text-theme-800">Section 40A(2) refers to payments made to relatives and associates. The modified definition of related party under section 40A(2)(b) and the proviso read as under:</p>
<p className='my-2 text-theme-800'>“Provided that no disallowance, on account of any expenditure being excessive or unreasonable having regard to the fair market value, shall be made in respect of a specified is at arm’s length price as defined in clause (ii) of section 92F.</p>
<p className="my-1 text-theme-800">(b) The persons referred to in clause (a) are the following, namely;-</p>
<p className="my-2 text-theme-800">(i) where the assessee is any relative of the assessee;
an individual</p>
<p className="my-1 text-theme-800">(ii) (ii) where the assessee is a company, firm association of persons or Hindu undivided family any director of the company/partner of the firm, or member of association or family, or any relative of such director, partner or member;</p>
<p className="my-2 text-theme-800">(iii) (iii) any individual who has a substantial interest in the business or profession of the assessee, or any relative of such individual;</p>
<p className="my-2 text-theme-800">(iv) (iv) a company, firm, association of persons or Hindu undivided family having a substantial interest in the business or profession of the assessee or any director, partner or member or any other company carrying on business or profession in which the first mentioned company has substantial interest;</p>
<p className="my-2 text-theme-800">(v) (v) a company, firm, association of persons or Hindu undivided family of which a director, partner or member, as the case may be, has a substantial interest in the business or profession of the assessee; or any director, partner or member of such company, firm, association or family or any relative of such director, partner or member;</p>
<p className="my-2 text-theme-800">(vi) any person who carried on business or profession,-</p>
<p className="my-2 text-theme-800">(A) Where the assessee being an individual, or any relative of such assessee, has a substantial interest in the business or profession of that person; or</p>
<p className="my-2 text-theme-800">(B) Where the assessee being a company, firm, association of persons or Hindu undivided family, or any director of such company, partner of such firm or member of the association or family, or ay relative of such director, partner or member, has a substantial interest in the business or profession of that person.</p>
<p className="my-2 text-theme-800"> <strong>Explanation– </strong>For the purposes of this sub-section, a person shall be deemed to have a substantial interest in a business or profession, if.-</p>
<p className="my-2 text-theme-800">(a) In a case where the business or profession is carried on by a company, such person is, at any time during the previous year, the beneficial owner of shares (not being shares entitled to a fixed rate of dividend whether with or without a right to participate in profits)carrying not less than twenty per cent, or the voting power; and</p>
<p className="my-2 text-theme-800">(b) In any other case, such person is, at any time during the previous year, beneficially entitled to not less than twenty per cent of the profits of such business or profession”
For the above purpose, the word “relative” is defined in section 2(41) to mean husband , wife, brother, sister, or any lineal ascendant or descendant of the individual.</p>
<p className="my-2 text-theme-800">Where the assessee incurs any expenditure in respect of which payment has been or is to be made to certain persons, the Assessing Officer may disallow so much of the expenditure, which he considers excessive or unreasonable having regard to the fair market value of goods, services or facilities for which the payment is made,</p>
<p className="my-2 text-theme-800">Transfer pricing rules have some similarity with the disallowance under section 40A(2) with respect to payments made in excess of the arm’s length price. The Tribunal in Aztec Software and Technology Services Ltd v. ACIT[2007] 294 ITR (AT)32 (BANG) [SB] held that Chapter X is a complete code in itself applicable in respect of transaction with a non- resident associate in excess of arm’s length price. Section 40A(2) covers excessive or unreasonable payment made to related parties.
Section 40A(2) has been amended to the effect, that no disallowances would be made under this section in respect of specified domestic transactions, if such transactions are at arm’s length as defined under section 92F.The specified domestic transaction under section 92BA has been defined to mean “expenditure” and does not include income. There are also no clear answer in respect to managerial payments, so as to require a detailed analysis.</p>
<h1 className={`mt-2 text-theme-800 ${h1}`}>International Transactions versus Specified Domestic Transactions</h1>
</div>
<div className="my-5">
            <table className=" border border-blue-300table-fixed">
              <thead>
                <tr>
                  <th className=" border border-blue-300">
                    S No:</th>
                  <th className=" border border-blue-300">Subject</th>
                  <th className=" border border-blue-300">International Transactions</th>
                  <th className=" border border-blue-300">Specified Domestic Transactions</th>
                </tr>
              </thead>
              <tbody className="border border-blue-300">
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">1</td>
                  <td className="border border-blue-300 p-3">Eligible assessee</td>
                  <td className="border border-blue-300 p-3">All enterprises with international transactions</td>
                  <td className="border border-blue-300 p-3">All enterprises in transactions with related parties listed in section 40A(2)(b) of the Act.</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">2</td>
                  <td className="border border-blue-300 p-3">Eligible transactions</td>
                  <td className="border border-blue-300 p-3">All international transactions with associated enterprise as defined under section 92B</td>
                  <td className="border border-blue-300 p-3">Transactions being expenditure or interest or allocation of any cost or expense referred in section 92(2A) and transaction listed under section 92BA</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">3</td>
                  <td className="border border-blue-300 p-3">Compensatory adjustment</td>
                  <td className="border border-blue-300 p-3">Compensatory adjustment consequent on arm’s length price addition will not be available in the assessment of the other party to the transaction</td>
                  <td className="border border-blue-300 p-3">Compensatory adjustments for arm’s length addition in assessment of the other party is possible</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">4</td>
                  <td className="border border-blue-300 p-3">Tax holiday benefits</td>
                  <td className="border border-blue-300 p-3">Tax holiday benefits are not available to non-residents. Where, however, an income is brought to tax as that of PE from an activity for which a resident is eligible, it is entitled to relief in the light of anti- discrimination provision in the Double Taxation Avoidance Agreements as decided by the Tribunal in Rajeev Sureshbhai Gajwani v. ACIT [2011]8 ITR (Trib)616 (Ahd)[SB]. There is no information as to whether this decision has the acceptance of revenue.</td>
                  <td className="border border-blue-300 p-3">Transfer pricing rules are applicable when tax holiday benefits are available in respect of transactions referred under section 80A and those covered by relief provision under sections 10AA and 80IA in respect of residents vide section 92BA.</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">5</td>
                  <td className="border border-blue-300 p-3">Role of Transfer Pricing Officer</td>
                  <td className="border border-blue-300 p-3">Section 92CA(2) empowers the Transfer Pricing Officers to determine the arm’s length price for all international transactions referred to him and those which come to his notice but not reported by the assessee or referred by the Assessing Officer</td>
                  <td className="border border-blue-300 p-3">Specified domestic transactions also required reference of arm’s length price to Transfer Pricing Officers. But section 92CA(2A) giving suo moto powers to initiate action covers only international transactions</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">6</td>
                  <td className="border border-blue-300 p-3">ISafe Harbour Provisions</td>
                  <td className="border border-blue-300 p-3">Rule prescribed allow 5 per cent under specified circumstances under Notifications S.O. 1871 (E),dated 17-8- 2012. Other rules to cover more instances are at draft stage. Such draft rules were released on 14-8- 2013 for public comment.</td>
                  <td className="border border-blue-300 p-3">Basis exemption upto Rs. 20 crores is provided vide section 92BA. Draft rules when they become law may have persuasive value for specified domestic transaction</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">7</td>
                  <td className="border border-blue-300 p-3">Advance Pricing Agreement</td>
                  <td className="border border-blue-300 p-3">Rule 10F to 10T vide Notification No.2005(E), dated 30-8- 2012 ([2012)] 346 ITR (st.) 184) prescribed under section 92CC now cover only international transactions</td>
                  <td className="border border-blue-300 p-3">There is no amendment in section 92CC to include specified domestic transactions</td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">8</td>
                  <td className="border border-blue-300 p-3">	Computation provision</td>
                  <td className="border border-blue-300 p-3">Section 92C providing for computation is common for international and domestic transactions</td>
                  <td className="border border-blue-300 p-3"></td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">9</td>
                  <td className="border border-blue-300 p-3">Maintenance, keeping information and documents</td>
                  <td className="border border-blue-300 p-3">Section 92D is common both for international and domestic transactions.</td>
                  <td className="border border-blue-300 p-3"></td>
                </tr>
                <tr className="border border-blue-300">
                  <td className="border border-blue-300 p-3">10</td>
                  <td className="border border-blue-300 p-3">Audit report</td>
                  <td className="border border-blue-300 p-3">Section 92E is common both for international and domestic transactions.</td>
                  <td className="border border-blue-300 p-3"></td>
                </tr>
              </tbody>
            </table>
          </div>
 </div>

    </PageLayout>
  )
}

export default DomesticTransferPricing
