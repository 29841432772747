import { ourTeam } from "../lib/constants"

const h1 = "text-xl font-semibold mb-2"
const h2 = "font-semibold mb-1"

function AboutUs({ className, fullScreen }) {
  return (
    <section className={className}>
      <div
        className={
          "max-w-6xl mx-auto px-4 sm:px-6 " +
          (fullScreen ? "text-theme-800 py-10" : "text-white")
        }
      >
        <div className={"py-12 md:py-18" + (!fullScreen && "mx-4 md:mx-0")}>
          <div className="max-w-3xl mx-auto text-center layout__header">
            <h1 className="h2 mb-4">About Us</h1>
          </div>
          <h1 className={`my-2 ${h1}`}>Who We Are?</h1>
          <p
            className={
              "text-md  mb-4 " +
              (fullScreen ? "text-theme-500 py-6" : "text-gray-300")
            }
          >
            <span
              className={
                "font-semibold text-xl mb-2 " +
                (fullScreen ? "text-theme-800 py-6" : "text-white")
              }
            >
              SARASH JAIN & ASSOCIATES, Chartered Accountants,{" "}
            </span>
            established in 2015 is recognized as one of the reputed partnership firm
            of Chartered Accountants practicing in India. The firm is involved in
            providing varied Audit and consultancy services to an array of banks,
            Institutions, Associations, private business houses, Business Plans,and
            Business Models. The firm has garnered a distinction for rendering
            services of the highest professional standards. It also provides
            expertise in Private Placement of Equity Shares, Preference Shares,
            Debentures and venture capital financing. It has necessary expertise and
            organizational strength to provide a comprehensive range of services to
            its clients. The quality of the team comprises of Chartered Accountants,
            Company Secretary, MBAs, Technical Experts and Associates, having
            expertise and experience in corporate consultancy and audits.{" "}
            {fullScreen ? (
              <div>
                The firm's aim is to help clients with top most priority and build
                tomorrow’s organizations. We provide real world solutions to complex
                business issues through audit and assurance functions,
                taxation (international and domestic), startup in India, company
                formation in India and foreign investment in India etc.
              </div>
            ) : (
              <a className="text-white underline decoration-solid" href="/about">
                Read More
              </a>
            )}
          </p>
        </div>
        <h1 className={`my-2 ${h1}`}>OUR TEAM</h1>
        <div className="team flex flex-col justify-start align-middle">

          {
            ourTeam.map((member) => (
              <div className="flex flex-row justify-enter align-middle mt-4 py-3 xs:flex-col team" key={member.id}>
                <img src={member.image} alt="" width="200px" height="150px" className="team__image" />
                <div className="flex flex-col justify-enter align-middle p-3 ml-4">
                  <h1 className={` ${h1}`}>{member.name}</h1>
                  <h5 className={`${h2}`}>{member.position}</h5>
                  <p className={`text-sm text-theme-800 w-[100%] `}>{member.description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}
export default AboutUs
