const Item = ({ name, review }) => {
  return (
    <div
      className="flex flex-col h-full p-4 gap-2 border border-solid border-theme-400 rounded-md"
      data-aos="fade-up"
    >
      <blockquote className="text-lg text-theme-400 grow">"{review}"</blockquote>
      <div className="text-theme-400 font-medium pt-2 border-t border-theme-800">
        <cite className="text-theme-800 not-italic">- {name}</cite>
      </div>
    </div>
  )
}

export default function Testimonials({ className }) {
  return (
    <section className={"md:min-h-screen " + className}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-10 md:pb-10">
            <h2 className="h2 text-theme-800">Don't take our word for it</h2>
          </div>

          {/* Testimonials */}
          <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-3 lg:gap-6 items-start lg:max-w-none">
            {/* 1st testimonial */}
            <Item
              name="Sahil Gupta"
              review={`Sarash has a very polite behaviour and delivers quality in work. Has
                vast knowledge of tax related matters and is one stop for all the
                issues/requirements.`}
            />
            <Item
              name="Ansh Garg"
              review={`The CA services provided were exceptional, with their knowledgeable team guiding us through complex financial matters and ensuring compliance with utmost professionalism`}
            />
            <Item
              name="Sachin Rana"
              review={`It was a grand experience with Sarash Jain & Associates. Mr Sarash jain is a humble, polite and knowledgeable person with good skills. He will satisfy you with all your doubts regarding your issue. Overall it was awesome experience.`}
            />

            <Item
              name="Renuka Garg"
              review={`The CA services provided were exceptional, offering expert financial guidance and ensuring a seamless experience throughout`}
            />
            <Item
              name="Nancy jain"
              review={`I got great guidance and support from CA Sarash Jain. He displayed a genuine interest in listening and addressing all the problems.`}
            />
            <Item
              name={"Surender Saini"}
              review={`Great guidance and services provided by the firm and very knowledgeable staff.`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
