import React from "react"
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"

const StatutoryRequirements = () => {
  return (
    <PageLayout header={"STATUTORY REQUIREMENTS"}>
      <div className="flex flex-col justify-between align-middle mt-[10px] w-[100%]">
        <div className="flex flex-row justify-between align-middle overview">
          <div className="flex flex-col justify-between align-middle pr-4 ">
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Introduction</h2>
              <p className="text-theme-800"><strong>Indian companies</strong>are now governed by Companies Act 2013 and company has to comply with various statutory provisions as per different sections of Companies Act 2013. Services offered by us include:</p>
              <ol className="my-2">
                <li>1. Incorporation of company</li>
                <li>2. Filing of documents with Registrar of Companies</li>
                <li>3. Conducting Statutory Audit at the year end.</li>
                <li>
                  4. Assistance in drafting Director’s Report covering statutory
                  points to be covered.
                </li>
                <li>
                  5. Assistance covering Annual General Meeting and Statutory
                  Compliance thereof.
                </li>
                <li>
                  6. Statutory provisions relating to various meetings like Board
                  Meetings, StatutoryMeetings, their due dates and documents to be
                  filed with Registrar of Companies.
                </li>
                <li>
                  7. Consultancy for other different provisions as applicable to
                  company.
                </li>
              </ol>
            </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Company Law</h2>
              <h2 className={`my-2 ${h1}`}>Forms of commercial organizations</h2>
              <p className="text-theme-800">A company is one which is registered under the provisions of the Companies Act 2013 (Companies Act). A company may be either a private company which by its constitutional documents, restricts the right of its members to transfer their shares in the company, prohibits invitation to the public to subscribe to its shares, debentures or other securities or to invite deposits from the public and which limits the total number of its members to 200 or a public company which does not have prohibition as to transfer of shares or restriction on number of shareholders.</p>
              <p className="text-theme-800">Further, the Companies Act, 2013 has newly introduced the concept of One Person Company (OPC) that is to say, a private company in which only a natural person who is an Indian citizen and resident in India shall be eligible to be the sole member.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Dormant Company [New Concept under 2013 Act]</h2>
                    <p className="text-theme-800">A company not having any significant accounting transaction, and which is formed under the 2013 Act for a future project or to hold an asset or an intellectual property or an inactive company may obtain status of a dormant company by applying to the ROC, or</p>
                    <ul className="list-disc my-2">
                      <li>A company which has not filed financial statements or annual returns for last 2 <strong>2 FYs consecutively</strong>will be classified as Dormant Company by the ROC.</li>
                      <li>Board meeting is required to be held at least <strong>once in each half </strong> of a calendar year and the gap between the 2 meetings is not less than 90 days.</li>
                      <li>A dormant company will have such number of directors, file such documents and pay such annual fees as may be prescribed</li>
                    </ul>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Minimum paid-up capital</h2>
              <p className="text-theme-800">All private and public companies are required to have a minimum paid-up capital of INR 100,000 and INR 500,000 respectively (though the amounts has been omitted by Companies Act, 2013). Incase certain key words like manufacturing, India, etc, are to be used, there are additional minimum paid-up capital requirements.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Two kinds of share capital</h2>
                <p className="text-theme-800">The share capital of a company may be of two types, namely, equity share capital and preference share capital. The equity share capital may be issued with voting rights or with differential rights as to dividend, voting or otherwise in accordance with the rules and subject to such conditions prescribed by the Companies (Issue of shares with Differential Voting Rights) Rules, 2001. The preference share capital enjoys a preferential right in the payment of dividends during the lifetime of the company and repayment of capital when the company is wound up. The preference share can be of the following types:</p>
                <ul className="list-disc my-2">
                  <li>Cumulative and Non-cumulative preference shares</li>
                  <li>Convertible and Non-convertible preference shares;</li>
                  <li>Participating and Non-participating preference shares; or</li>
                  <li>Redeemable and Irredeemable preference shares.</li>
                </ul>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>New issues of equity share capital with different voting rights</h2>
              <p className="text-theme-800">A public company may issue equity shares with differential rights as to dividends, voting or otherwise in accordance with the rules made in that behalf. This amendment would help management to raise capital without diluting control by issuing shares without voting rights. At the same time, investors would have the benefit of receiving dividends and bonus shares.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Shifting of registered office</h2>
              <p className="text-theme-800">A company proposing to shift its registered office from one state to another is required to pass a special resolution (resolution passed with three-fourths majority) at a meeting of its shareholders. A company proposing to change the place of its registered office from the jurisdiction of one Registrar of Companies to the jurisdiction of another Registrar of Companies (RoC) within a state will have to an application seeking confirmation from the Regional Director for shifting the registered office within the same State from the jurisdiction of one Registrar of Companies to the jurisdiction of another Registrar of Companies, shall be filed by the company with the Regional Director in Form no.INC.23 along with the fee.</p>
              <p className="text-theme-800">Notice of every change of the situation of the registered office, verified in the manner prescribed, after the date of incorporation of the company, shall be given to the Registrar within fifteen days of the change, who shall record the same. The confirmation from the RD shall be communicated within a period of thirty days from the date of receipt of application by the Regional Director to the company and the company shall file the confirmation with the Registrar within a period of sixty days of the date of confirmation who shall register the same and certify the registration within a period of thirty days from the date of filing of such confirmation. The certificate shall be conclusive evidence that all the requirements of the Act with respect to change of registered office have been complied with and the change shall take effect from the date of the certificate.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Buy back of securities</h2>
              <p className="text-theme-800">Subject to the provisions of sec 77A, a company may buy back its own securities up to a maximum extent of 25% of its paid-up share capital in any financial year, subject to fulfilling certain conditions. A company may also issue sweat equity shares of a class of shares already issued subject to certain conditions specified under sec 79A.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}> <strong>a.</strong>Directors</h2>
              <p className="text-theme-800"><strong>a</strong>. Every public company is required to have at least three directors and every private company to have at least two directors, subject to a maximum of 12 directors. However, One Person Company may have minimum one director.</p>
              <p className="text-theme-800">A listed public company in India is required to comply with the corporate governance requirements. Accordingly, such a company is mandatorily required to have not less than 50% of the total strength of the board of directors comprising non-executive directors. Depending on whether or not the Chairman is executive or non-executive, the total number of independent directors that it shall appoint will vary between half or one third respectively. Independent directors have been defined to mean directors, who apart from receiving director’s remuneration, do not have any other material pecuniary relationship or transaction with the company, its promoters, its management or its subsidiaries which in the judgment of the board, may affect the independence of judgment of the director.</p>
              <p className="text-theme-800">Listed public companies covered under the prescribed class should have at least one-third of the total number of its directors as independent directors. In the Directors’ Appointment Rules, minimum number of directors for non-listed public companies meeting prescribed criteria has been changed to 2, irrespective of the board size. Also, the criteria for appointment of independent directors have changed in the Directors’ Appointment Rules.</p>

              <p className="text-theme-800"><strong>b</strong>. No person can hold office of director in more than 20 companies at a time. 2013 Act restricts the number of directorship for an individual to 20. Out of total 20 companies, number of public companies in which a person can be appointed as a director cannot exceed 10.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Independent Director</h2>
              <p className="text-theme-800">Every company having:</p>
              <ul className="list-disc my-1">
                <li>Net worth of INR 5 billion or more, or</li>
                <li>Turnover of INR 10 billion or more, or</li>
                <li>Net profit of INR 50 million or more,</li>
              </ul>
              <p className="text-theme-800">shall appoint an independent director who is a director (other than managing director or whole-time director or nominee director), who is a person of integrity and has no pecuniary relationship with the company or its holding/ subsidiary/ associate company. Further, he shall not hold (together with his relative) two percent or more of the total voting power of the company.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Tenure:</h2>
              <p className="text-theme-800">The initial term shall be five years, following which further appointment would require a special resolution of shareholders. However, total tenure shall not exceed two consecutive terms, without a break of three years.</p>
              </div>
            <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Role in the company:</h2>
              <p className="text-theme-800">The role is broadly set out in Schedule IV of 2013 Act. An Independent director acts as “watchdog” for the company’s shareholders. He may also be viewed as strategic advisors who can provide their expertise and experience on business matters to the firm’s management or controlling shareholders.</p>
              </div>
              <div className="my-2">
              <h2 className={`my-2 ${h1}`}>Mandatory Requirements as Directors:</h2>
              <ul className="list-disc p-3">
              <li><strong>One Resident Director</strong></li>
              <p className="mb-2 text-theme-800">At least one director must be resident in India, that is to say, he must have stayed in India for at least 182 days in previous calendar year</p>
              <li><strong>Board Meeting</strong></li>
              <p className="mb-2 text-theme-800">Director must be present in at least one board meeting held during the period of 12 months with or without leave of absence, otherwise, he shall be considered to vacate the office. However, board meeting of OPC is required to be held at least once in each half of a calendar year and the gap between the 2 meetings is not less than <strong>90 days</strong> .</p>
              <li><strong>Declaration</strong></li>
              <p className="mb-2 text-theme-800">An individual before being appointed as director shall be required to furnish a declaration that he/ she is not disqualified for being appointed as director.</p>
              <li><strong>Consent filing</strong></li>
              <p className="mb-2 text-theme-800">Every director has to give his consent for appointment as such to be filed with Registrar of Companies</p>
              <li><strong>Resignation filing</strong></li>
              <p className="mb-2 text-theme-800">There is mandatory requirement for the person to file his resignation with Registrar as director within 30 days. Resignation shall be effective from the date of receipt by company, not w.e.f. date of resignation</p>
              <li><strong>Limit on directorships</strong></li>
              <p className="mb-2 text-theme-800">Directorship in Private company, One Person Company, and LLP (Limited Liability Partnerships) to be counted in total number of directorships (i.e, twenty), allowable for a person being a director[s 165(1)].</p>
              <p className="text-theme-800">
                <em>Directorship in Private company, One Person Company, and LLP (Limited Liability Partnerships) to be counted in total number of directorships (i.e, twenty), allowable for a person being a director[s 165(1)].</em>
              </p>
              </ul>
            </div>
          </div>
          <div className="flex flex-col justify-between align-middle pl-4">
            <div className="my-2">
              <ul className="list-disc p-3">
              <li><strong></strong>Quorum</li>
              <p className="mb-2 text-theme-800">One-third of the total directors or two, whichever is higher shall form the quorum for the board meeting, wherein no proxy shall be counted. However, such provisions shall not apply to One Person Company.</p>
              <li><strong>Loan to directors</strong></li>
              <p className="mb-2 text-theme-800">No company shall directly or indirectly advance any loan (including loan represented by a book debt) or give guarantee or provide security in connection with such loan to any director / related persons.</p>
              <p className="text-theme-800">An exception to the above rule is made for MD or a whole time director (WTD) if such loan is in accordance with the terms of services extended to all employees or is approved by shareholders by special resolution.</p>
              <li><strong>Key Managerial Personnel</strong></li>
              <p className="mb-2 text-theme-800">Company having a paid-up share capital of fifty million rupees or more shall have whole-time key managerial personnel. It shall include:</p>
              <p className="text-theme-800">a) Managing Director/ Chief Executive Officer/ Manager/ Whole-time Director, and,</p>
              <p className="text-theme-800">b) Company Secretary, and</p>
              <p className="text-theme-800">c) Chief Financial Officer</p>
              <p className="text-theme-800">Each of these classes of KMP is independent and mandatory.</p>
              <p className="text-theme-800">
                <em>Such whole-time KMP shall not hold office in more than one company, except in its subsidiary at the same time.</em>
              </p>
              <p className="text-theme-800">*Transition period of 6 months is allowed from commencement of this Act.</p>
              <p className="text-theme-800"><strong>c.</strong> <em>Now, directors have to annually report in the Directors’ Responsibility Statement, on the <strong>existence and effective operations of systems on compliance with all applicable laws.</strong></em>
              </p>
              <p className="text-theme-800"><strong>d.</strong>As per 2013 Act, the board of directors is required to meet at least four times in a calendar year. No such meetings need be held compulsorily in India and can be held anywhere in the world.</p>
              <ul className="list-disc my-2">
                <li>Maximum gap between two board meetings should <strong>not be more than 120 days</strong>.</li>
                <li>Electronic voting for Board and shareholders’ meetings has been introduced.</li>
                <li>Approval of related party transactions by Board of Directors at Board meeting made mandatory.</li>
                <li>At least 7 days’ notice to every director shall be given for the board meeting.</li>
                <li>As till now the foreign directors faced location constraints in attending the board meetings in India, now participation of directors through video-conferencing and other electronic means for board meetings has been permitted and considered equivalent to being in- person, provided such participation is capable of recording and recognizing. [s173(2)]
                *Further, such meeting can be held anywhere around the world with the aid of video-conferencing.</li>
              </ul>
         
              </ul>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Appointment of Statutory Auditor:</h2>
                <ul className="list-disc my-2">
                  <li>
                    The Board of directors is required to appoint the first auditor
                    within 30 days of registration of company and in case of failure
                    to do so, the members shall appoint the first auditors within 90
                    days at the EGM, however, such auditors have to vacate the
                    position on conclusion of first AGM.
                  </li>
                  <li>
                    Every auditor (except first auditor) including LLP, shall be
                    automatically appointed for a tenure of five years with/ without
                    re-appointment in the subsequent AGMs.
                  </li>
                  <li>
                    Auditor’s consent and certificate need to be obtained by the
                    company explaining that such appointment is in compliance with
                    the provisions of the Act and satisfies the criteria provided in
                    section 141.
                  </li>
                  <li>
                    Intimation (or notice) of appointment, now, need to be filed by
                    the company with the Registrar within fifteen days of
                    appointment.
                  </li>
                </ul>
              </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Corporate Governance</h2>
                  <p className="text-theme-800">As a step in the direction of corporate governance, the following provisions have been introduced in the Companies Act:</p>
                  <p className="text-theme-800">Section 134 provides that a report by the Board of Directors containing details on the matters specified including directors’ responsibility statement shall be attached to every financial statement laid before a company. The responsibility statement includes that the applicable Accounting Standards have been followed in preparing the financial statements and reporting the material departures therefrom, that the companies follow their accounting policies consistently, the accounts have been prepared on a going concern basis and compliance of all applicable laws.</p>
                  <p className="text-theme-800">Section 177 provides the requirements and manner of constituting the Audit Committee. The Audit Committee shall consist of minimum three directors with Independent Directors forming a majority and majority members must have ability to read and understand financial statements. The Section also provides for a vigil mechanism in every listed and prescribed class of companies and such mechanism shall be disclosed at the website of the company and should be mentioned in Board’s report.</p>
                  <p className="text-theme-800">Section 184 provides the manner and periodicity in which the every director shall made disclosure of his concerns or interest in any company, body corporate, firms and parties to the contract. He concerned director should not participate in the meeting taking the decision in such cases. The contract or agreement entered in to by the company without disclosure shall be voidable at the option of the company.</p>
                    </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Woman Director</h2>
                  <p className="text-theme-800">The 2013 Act requires prescribed class of companies to have at least one woman director on the board. In accordance with the Act, existing companies meeting the prescribed criteria need to comply with the requirement within one-year i.e. March 31, 2015.</p>
                    </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Risk management committee</h2>
                  <p className="text-theme-800">As per the revised clause 49, the company through its Board of Directors shall constitute a Risk Management Committee. The Board shall define the roles and responsibilities of the Risk Management Committee and may delegate monitoring and reviewing of the risk management plan to the committee and such other functions as it may deem fit.</p>
                  <p className="text-theme-800">he majority of Committee shall consist of members of the Board of Directors. Senior executives of the company may be members of the said Committee but the Chairman of the Committee shall be a member of the Board of Directors.</p>
                  <p className="text-theme-800">The Companies Act 2013, requires its Board of Directors to develop and implement a risk management policy and identify risks which may threaten the existence of the Company. It however does not require constitution of a separate risk management committee as required by revised clause 49.</p>
                  <p className="text-theme-800">However, SEBI has exempted the applicability of Clause 49 (of the Listing Agreement) for Companies having paid up equity share capital not exceeding Rs. 10 crore and Net Worth not exceeding Rs. 25 crore, as on the last day of the previous financial year.</p>
                    </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Statutory accounts</h2>
                  <p className="text-theme-800">At every annual general meeting of a company, the Board of directors is required to lay a balance sheet and a profit and loss account. The profit and loss account shall relate to the financial year of the company and the balance sheet, as at the end of the financial year. Financial Year in relation to financial statements shall be the period ending on the 31st day of March every year. * Transition period of two years was initially allowed for an existing company to align its financial year as per this provision.</p>
                  <p className="text-theme-800">The <span className="underline">financial statements</span> shall give a true and fair view of the state of affairs of the <span className="underline">company</span> or companies, comply with the <span className="underline">accounting standards</span> notified under <span className="underline">section</span> 133 and shall be in the form or forms as may be provided for different class or classes of companies in <span className="underline">Schedule III</span>. Where a company has one or more subsidiaries, it shall, in addition to financial statements, prepare a consolidated financial statement of the company and of all the subsidiaries in the same form and manner as that of its own which shall also be laid before the annual general meeting of the company along with the laying of its financial statement. It includes consolidation of subsidiary, associates and Joint Ventures, cash flow statement, statement of changes in equity, any explanatory note, disclosure of CSR activity, as prescribed in 2013 Act.</p>
                  <p className="text-theme-800">Financial statements shall be prepared in accordance with <strong>Schedule III</strong> of Companies Act, 2013.</p>
                    </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Annual return</h2>
                  <p className="text-theme-800">A copy of the <span className="underline">financial statements</span>, including consolidated financial statement, if any, along with all the documents which are required to be or attached to such financial statements under this Act, duly adopted at the annual general meeting of the company, shall be filed with the Registrar within thirty days of the date of annual general meeting in such manner, with such fees or additional fees <span className="underline">as may be prescribed</span> within the time specified under <span className="underline">section 403</span> :</p>
                  <p className="text-theme-800">A <span className="underline">One Person Company </span> shall file a copy of the financial statements duly adopted by its member, along with all the documents which are required to be attached to such financial statements, within one hundred eighty days from the closure of the financial year.</p>
                  <p className="text-theme-800">Provided also that a company shall, along with its financial statements to be filed with the Registrar, attach the accounts of its subsidiary or subsidiaries which have been incorporated outside India and which have not established their place of business in India.</p>
                    </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>
                  Annual General Meeting and other General Meetings:
                </h2>
                <ul className="list-disc my-2">
                  <li>
                    AGM is the annual event of shareholders of company and is a good
                    example of shareholders’ democracy.
                  </li>
                  <li>
                    First AGM to be held within 9 months of closure of financial year
                    and every subsequent meeting <strong>within six months </strong>
                    from the date of closing of financial year.
                  </li>
                  <li>
                    Maximum gap between two AGMs shall be <strong>15 months</strong>.
                  </li>
                  <li>
                    Meeting shall be held either at the registered office of the
                    company or at some other place within the city, town or village
                    in which the registered office of the company is situated
                  </li>
                  <li>
                    No relief to foreign residents for attending general meetings
                    through video conferencing or holding meetings outside the city
                    where registered address of the company is situated. Every member
                    has to present in person.
                  </li>
                  <li>
                    At least 21 days’ notice shall be given for every general
                    meeting.
                  </li>
                  <li>
                    Any general meeting, in addition to AGM, shall be called
                    “Extra-Ordinary General Meeting” (EGM).
                  </li>
                  <li>A One Person Company need not hold an AGM</li>
                </ul>
              </div>
              <div className="my-2">
                <h2 className={`my-2 ${h1}`}>Corporate Social Responsibility <em>[Newly applicable reporting provision]</em></h2>
                  <p className="text-theme-800">Every company having <span className="underline">net worth</span> of rupees five billion or more, or <span className="underline">turnover</span> of rupees ten billion or more or a net profit of rupees fifty million or more during any financial year shall constitute a Corporate Social Responsibility Committee of the Board consisting of three or more directors, out of which at least one director shall be an <span className="underline">independent director</span> . The Board’s report under section 134 shall disclose the composition of the Corporate Social Responsibility Committee. The Board of every company shall ensure that the company spends, in every financial year, at least <strong>two per cent of average net profits</strong> of the company made during three immediately preceding financial years, in pursuance of its Corporate Social Responsibility Policy.</p>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default StatutoryRequirements
