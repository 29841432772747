import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"

const CompanyFormationProcedure = () => {
  return (
    <PageLayout header={"Company Formation Procedure"}>
      <div className="flex flex-col justify-between align-middle layout__header w-[100%]">
        <div className="flex flex-col justify-between align-middle">
          <h1 className={`my-2 ${h1}`}>Applicable law</h1>
          <p className="text-theme-800">The Indian Companies Act, 2013
            For company registrations In India, the Companies Act of 2013 sets down rules for the establishment of both public and private companies.</p>
        </div>
        <div className="flex flex-col justify-between align-middle">
          <h1 className={`my-2 ${h1}`}>Allotment of Director Identification Number (DIN)</h1>
          <p className="text-theme-800">Application in Form DIR-3 shall be made online and provisional DIN number of the person intending to become director of the Company shall be generated.</p>
        </div>
        <div className="flex flex-col justify-between align-middle">
          <h1 className={`my-2 ${h1}`}>Acquiring Digital Signature certificate (DSC)</h1>
          <p className="text-theme-800">Digital Signature Certificate is acquired by submitting the application alongwith prescribed fee to one of the various vendors like TCS, Satyam etc. On submission of documents, DSC is allotted in 1-2 days.</p>
        </div>
        <div className="flex flex-col justify-between align-middle">
          <h1 className={`my-2 ${h1}`}>Name Approval of the company</h1>
          <p className="text-theme-800">An application in Form INC-1 needs to be filed with the Registrar of Companies (ROC) online through Digital Signature of one of the proposed director. On submitting the application, the ROC scrutinizes the same and sends the approval / objections in about 3-4 days to the applicant through e-mail.</p>
        </div>
        <div className="flex flex-col justify-between align-middle">
          <h1 className={`my-2 ${h1}`}>Procedure after name approval of the company</h1>
          <p className="text-theme-800">An application for registration should be submitted to the Registrar of Companies with the following documents</p>
          <ul className="list-disc my-2">
            <li>Memorandum of Association;</li>
            <li>Articles of Association;</li>
            <li>Utility Bill for Registered Office (not older than 2months);</li>
            <li>Declaration by first Subscribers and Directors;</li>
            <li>
              Identity and residential proof of all the Subscribers (such as PAN,
              Aadhaar Card, Bank Statement, Voter ID, Driving License, Passport);
            </li>
            <li>
              Rent Property duly notarized and NOC from the owner of the property (in
              case of rented property);
            </li>
            <li>
              E form DIR 2 (consent from all the Directors to act in such capacity);
            </li>
            <li>
              Applicable registration fee payable to the Registrar of Companies.
            </li>
            <br />
            <p>
              {" "}
              The Next step in the incorporation is to submit the following Forms:
            </p>

            <li> a. Fill the details in the e-AOA in form no. INC-34</li>
            <li> b. Fill the details in the e-MOA in form no. INC-33</li>
            <li>
              {" "}
              c. Fill the details in the e-form AGILE PRO for obtaining Goods and
              Services Tax Identification Number/Employees’ Provident Fund
              Organization/Employees’ State Insurance Corporation/Professional Tax
              Registration and Opening Bank Account (as may be applicable)
            </li>
            <li> d. Form INC 9</li>
          </ul>
        </div>
      </div>
    </PageLayout>
  )
}

export default CompanyFormationProcedure
