import { Link } from "react-router-dom"
import SocialLinks from "./SocialLinks"
import MobileMenu from "./mobile-menu"
import MenuBarItems from "./MenuBarItems"
import { Logo } from "../assets"

export default function Header() {
  return (
    <header className="w-full z-30 bg-theme-800 absolute" >
      <div className="bg-theme-100 h-25 md:h-10">
        <SocialLinks />
      </div>
      <div className="max-w-8xl mx-auto px-4 sm:px-6">
        <div className="flex gap-6 md:gap-0 items-center justify-between h-20 md:h-30">
          {/* Site branding */}

          {/* Logo */}

          <Link
            to="/"
            relative="path"
            className=" object-cover flex flex-row justify-center header__logo align-middle justify-self-start"
            aria-label="Logo"
          >
            <div className="logo__box">
              <img src={Logo} className="h-20 md:h-30" alt="logo" />
            </div>
          </Link>
          {/* <Link
            to="/"
            relative="path"
            className="block object-cover"
            aria-label="Logo"
          >
            <img src="/logo.png" className="h-18 md:h-30" alt="logo" />
          </Link>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop sign in links */}
            <MenuBarItems
              className={"flex grow justify-end flex-wrap items-center"}
            />
          </nav>
          <MobileMenu />
        </div>
      </div>
    </header>
  )
}
