import React, { useState } from 'react'
import PageLayout from "../../PageLayout"
import ProjectOffice from './ProjectOffice'
import LiaisonOffice from './LiaisonOffice'
import BranchOffice from './BranchOffice'


const StartBusinessOverview = () => {
  const [activeTab, setActiveTab] = useState("Project Office");
  return (
    <PageLayout header={"STARTING BUSINESS IN INDIA"}>
      <div className="d-flex flex-col justify-start align-middle border layout__header border-blue-300 ">
        <div className="d-flex flex-row justify-center align-middle mb-5 ">
          <button className={ activeTab === "Liaison Office" ? "py-4 px-3 border border-blue-300 mr-1 border-b-0" :
        "py-4 px-3 border border-blue-300 mr-1"} onClick={() => setActiveTab("Liaison Office")}>Liaison Office</button>
          <button className={ activeTab === "Project Office" ? "py-4 px-3 border border-blue-300 mr-1 border-b-0" :
        "py-4 px-3 border border-blue-300 mr-1"} onClick={() => setActiveTab("Project Office")}>Project Office</button>
          <button className={ activeTab === "Branch Office" ? "py-4 px-3 border border-blue-300 mr-1 border-b-0" :
        "py-4 px-3 border border-blue-300 mr-1"} onClick={() => setActiveTab("Branch Office")}>Branch Office</button>
        </div>
        {
          activeTab === "Project Office" ?
            <ProjectOffice /> : activeTab === "Liaison Office" ? <LiaisonOffice /> : <BranchOffice />

        }
      </div>
    </PageLayout>
  )
}

export default StartBusinessOverview