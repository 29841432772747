import { services } from "../lib/constants"



const  OurServices = ({ className, fullScreen }) => {
  return (
    <section className={className}>
      {/* <img src={ServicesImage} alt="" height="200px" width="100%"/> */}
      <div className={"max-w-6xl mx-auto px-4 sm:px-6  w-[100%]" + (fullScreen && "py-10")}>
        <div className={"py-12 md:py-18" + (!fullScreen && " mx-4 md:mx-0")}>
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-6 md:pb-16 w-[100%]">
            <h1 className="h2 mb-4 text-theme-800  ">Our Services</h1>
          </div>
          {/* <div className="grid md:grid-cols-12"> */}
          <div className="flex justify-center align-middle flex-wrap servicesContainer w-[100%]">
           {
            services.map((item) => (
              <div
              className="p-[10px] flex flex-col justify-start align-middle rounded-md border-2  border-theme-500 w-[340px] xs:w-[100%] m-[10px]"
              data-aos=""
              key={item.id}
            >
              <div className="flex flex-col justify-center align-middle  w-[100%]">
             <span className="my-3 relative left-[40%] rounded-full bg-theme-600 w-[50px] h-[50px]">{<item.itemIcon size={30} color="#fff" className="relative top-[20%] bottom-0 left-[20%] right-0  opacity-75"/> }</span>
                <div className="text-2xl mb-2 h4 text-center"> {item.title}</div>
                <p className="text-lg text-theme-600  mb-4">{item.description}</p>
              </div>
            </div>
            ))
           }
            
          </div>
        </div>
      </div>
    </section>
  )
}
export default OurServices
