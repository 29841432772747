import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const TrendsInIndia = () => {
    return (
        <PageLayout header={"TRENDS IN INDIA"}>
            <div className="flex flex-col justify-between align-middle layout__header w-[100%]" >
                <div className="my-2">
                    <h2 className={`my-2 ${h1}`}>Trends in India</h2>
                    <p className='my-1 text-theme-800'><strong>The employment of expatriates and foreign nationals </strong>involves several complex issues. Whist the Indian economy and market place has generally been less attractive to expatriates and foreign workers, they are still employed to provide training and development to local employees, for technology transfer, compliance of joint ventures and license agreements and so on. With the liberalization of the Indian economy in the early nineties and subsequent entry and establishment of many multinational companies, the need to employ expatriates and foreign nationals became more obvious.</p>
                    <p className="my-1 text-theme-800">Generally in multinational companies, expatriates and foreign nationals are engaged in key and higher level positions. When a foreign company sets up a business operations in India, the management of these companies tends to seek the services of their own employees for management level positions. Typically, these companies tend to bring in the Managing Director, CEOs and Country Heads from their operations and other parts of the world. The trend has been to employ Indian nationals at the next level of operations like General Manager in different functional areas. This level of personnel serves not only as the functional workforce but also in an advisory capacity to the foreign business head. In many cases, the objective is to replace the expatriate with the Indian manager in the course of time, through career progression. In Joint Ventures (JVs) between Indian and foreign partners, the two often agree on who will appoint the key managers in the operation. Depending upon the industry, the products or services, the needs of enterprises, foreign company status and other factors, the foreign partner may insist on appointing the Managing Director or the Country Head or the Finance Head. The local partner often appoints the head of HR. In JV situations, unfortunately, the insistence on appointing certain key managers may stem from mistrust between the two partners rather than from the need of the enterprise.</p>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Legislative restrictions</h2>
                        <p className="text-theme-800">In the legal context there are no specific legislative restrictions on the type of skills that may be brought in nor the numbers of staff. However, under Government Policy employment visas to foreigners are issued only for jobs requiring high skills and expertise. The principal Indian legislation regarding foreigners is contained in the Registration of Foreigners Act 1939 and the Foreigner Act 1946 which also provides the regulation framework for the registration, movement and departure of foreigners. The Indian government has complete power to regulate and restrict the movement and presence of foreigners in India. The power to grant or refuse foreigners entry into India and subsequent restrictions on the employment are covered in the Foreigners Order 1948 (1948 Order). The legislation and judicial decisions are stringent pertaining to the entry, presence and movement of foreigners in India, including business visitors.</p>
                        <p className="my-1 text-theme-800">Under Foreigners Order 1948, no foreigner shall, without the written permission from the appropriate authority, accept employment in or in connection with:</p>
                        <p className="text-theme-800">Other deductions from the income of the foreign national include, deductions:</p>
                        <ul className="list-disc my-1">
                            <li>Any undertaking for supplies of light, petroleum, power or water to the Government of India or to the public; and</li>
                            <li>Any other undertaking which may be specified by the Central Government in this behalf.</li>
                        </ul>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Requirements</h2>
                        <p className="text-theme-800">The Government authorities dealing in issues relating to expatriate employment in India are the Ministry of Home Affairs and RBI In general, employers who are employing expatriates in India must satisfy the following conditions:</p>
                        <ul className="list-disc my-1">
                            <li>The employer needs to prepare and send to the employee a contract letter and other documentation to assist in applying for an employment visa;</li>
                            <li>The employer must also sponsor the foreign employee and any dependents for registration; and</li>
                            <li>Indian companies employing foreigners are required to keep record as to the number of foreigners and Indians employed in different salary grades</li>
                        </ul>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Employment Visas</h2>
                        <p className="text-theme-800">All foreign nationals who enter India must obtain an appropriate visa from an overseas Indian Consular Post in their home country prior to entering India. A Foreigner entering India for the purpose of employment must possess a valid employment visa. An Employment Visa is given only for jobs that require very high level of skills and expertise.</p>
                        <h2 className={`my-2 ${h1}`}>Procedure</h2>
                        <p className="text-theme-800">The authorized capital is the capital limit authorized by the Registrar of Companies up to which the shares can be issued to the members / public, as the case may be. The paid up share capital is the paid portion of the capital subscribed by the shareholders.</p>
                        <ul className="list-disc my-1">
                            <li>A valid passport;</li>
                            <li>Proof of employment (appointment documents); and</li>
                            <li>Terms and conditions of employment.</li>
                        </ul>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Processing time</h2>
                        <p className="text-theme-800">Applications are generally processed on the same day. However, applications received from outside the Consular district with jurisdiction over the applicant’s usual place of residence may take up to five days to be processed.</p>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Maximum duration</h2>
                        <p className="text-theme-800">Multiple-entry employment visas are valid for one to five years. In other cases it is given for a specified period depending upon the need of the sponsoring organization, availability of Indian experts in the particular field, the benefit to be gained by the foreign nationals presence and necessity of visiting neighboring countries.</p>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Extensions</h2>
                        <p className="text-theme-800">A request for an extension of stay must be filed with the FRRO prior to the end of the employment visa. Consular posts cannot renew Employment Visas.</p>
                        <h2 className={`my-2 ${h1}`}>Family and dependents</h2>
                        <p className="text-theme-800">Family and dependents should apply for entry permits.</p>
                        <ul className="list-disc my-1">
                            <li>With the foreign national’s application for an employment visa; or</li>
                            <li>They must obtain their own employment visas in order to work in India; and</li>
                            <li>Children must obtain student visas to attend school in India</li>
                        </ul>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Registration of foreign workers</h2>
                        <p className="text-theme-800">Under the Registration of Foreigners Act 1939, every foreigner including foreign workers who hold long term visas (more than 180 days) and accompanying dependants are required to register their presence in India with the FRRO within 14 days of their arrival in India. In small cities, this is done through the Office of District Superintendent of Police. They must personally submit all papers for registration with the FRRO. The following categories of foreigners are exempted:</p>
                        <ul className="list-disc my-1">
                            <li>US nationals holding ten year tourist/business visas, provided their continuous stay in India during each visit does not exceed 180 days;</li>
                            <li>Foreigners holding five year multiple entry ‘X’ visa with an endorsement that ‘continuous stay should not exceed 180 days during each visit';</li>
                            <li>Foreigners holding five year tourist visa, who are actively engaged in tourism/travel trade, desirous of visiting India frequently for promotion of tourism, etc. provided their visas contains an endorsement that ‘continuous stay should not exceed 180 days during each visit';</li>
                            <li>Persons of Indian Origin (PIO) card holder, if their continuous stay during each visit does not exceed 180 days; and</li>
                            <li>Foreigners below the age of 16 years/</li>
                        </ul>
                        <p className="text-theme-800">Upon the registration of the report, a foreigner must provide the Registration Officer with documents, which satisfy the accuracy of the particulars in the report</p>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Essential Documents for Registration</h2>
                        <p className="text-theme-800">A foreigner holding an Employment Visa requires the following documents at the time of registration at FRRO:</p>
                        <ul className="list-disc my-1">
                            <li>A copy of the employment contract/appointment letter.</li>
                            <li>An undertaking from the employer/company.</li>
                            <li>Foreigners holding five year tourist visa, who are actively engaged in tourism/travel trade, desirous of visiting India frequently for promotion of tourism, etc. provided their visas contains an endorsement that ‘continuous stay should not exceed 180 days during each visit’;</li>
                            <li>Arrival report, issued by the prescribed authority.</li>
                        </ul>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Other requirements</h2>
                        <p className="text-theme-800">For the appointment of Managing Directors, Whole-time Director, Managers or any managerial personnel under the provisions of the Companies Act 1956 to the Board of a Company, approval of registration from the Department of Company Affairs is necessary.
                            For the appointment of a Partner in JV the following documents are required:</p>
                        <ul className="list-disc my-1">
                            <li>Copy of the approval from Government of India (in duplicate)</li>
                            <li>Copy of permission from the RBI; and</li>
                            <li>Copy of the contract of employment.</li>
                        </ul>
                    </div>
                    <div className="my-1 text-theme-800">
                        <h2 className={`my-2 ${h1}`}>Residence permit</h2>
                        <p className="text-theme-800">On registration, the FRRO issues a Registration Certificate/Residential Permit to the foreign national. A valid Residential Permit/Registration Certificate is also considered as a valid work permit. The Residence Permit will have the same validity as the Employment Visa. Residence Permits may be extended upon application. Registration is required only once during the validity of an Employment Visa irrespective of the number of times the foreigner leaves India on multi-entry facility.</p>

                    </div>
                </div></div>
        </PageLayout>
    )
}

export default TrendsInIndia