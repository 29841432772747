import React from "react"
import { URLs } from "../lib/constants"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

export default function MenuBarItems({ className, setMobileNavOpen }) {
  function closeMobileModal() {
    if (setMobileNavOpen) {
      setMobileNavOpen()
    }
  }
  const linkStyles =
    "font-semibold text-white text-xl hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"

  const services = [
    {
      id: 1,
      name: "Foreign Investment Approvals",
      options: [
        { id: 11, name: "Overview", href: URLs.FDI_OVERVIEW },
        {
          id: 111,
          name: "FDI-Prohibited Sectors",
          href: URLs.FDI_PROHIBITED_SECTORS,
        },
        {
          id: 1111,
          name: "FDI-Direct and Indirect Investments",
          href: URLs.FDI_DIRECT_AND_INDIRECT_INVESTMENT,
        },
      ],
    },
    {
      id: 2,
      name: "Auditing",
      options: [
        { id: 12, name: "Overview", href: URLs.FDI_AUDITING_OVERVIEW },
        { id: 122, name: "Internal Audit", href: URLs.FDI_INTERNAL_AUDIT },
         { id: 1122, name: "Service Tax Audit", href: URLs.FDI_SERVICE_TAX_AUDIT },
      ],
    },
    {
      id: 13,
      name: "Transfer Pricing",
      options: [
        {
          id: 113,
          name: "Transfer Pricing Audit",
          href: URLs.FDI_TRANSFER_PRICING_AUDIT,
        },
        {
          id: 123,
          name: "Transfer Pricing FAQs",
          href: URLs.FDI_TRANSFER_PRICING_FAQS,
        },
        {
          id: 103,
          name: "Domestic Transfer Pricing",
          href: URLs.FDI_DOMESTIC_TRANSFER_PRICING,
        },
      ],
    },
    {
      id: 0,
      name: "Company formation in India",
      options: [
        { id: 1023, name: "Overview", href: URLs.FDI_COMPANY_FORMATION_OVERVIEW },
        { id: 100, name: "FAQs", href: URLs.FDI_COMPANY_FORMATION_FAQS },
        { id: 1102, name: "Procedure", href: URLs.FDI_COMPANY_FORMATION_PROCEDURE },
        { id: 12123, name: "Checklist", href: URLs.FDI_COMPANY_FORMATION_CHECKLIST },
      ],
    },
    {
      id: 1568,
      name: "Business Taxation",
      options: [
        { id: 1071, name: "Overview", href: URLs.FDI_BUSINESS_TAXATION_OVERVIEW },
        {
          id: 1074,
          name: "Direct Taxation",
          href: URLs.FDI_BUSINESS_TAXATION_DIRECT_TAXATION,
        },
        {
          id: 1073,
          name: "Indirect Taxation - GST",
          href: URLs.FDI_BUSINESS_TAXATION_GST,
        },
        // {
        //   id: 1070,
        //   name: "Indirect Taxation",
        //   href: URLs.FDI_BUSINESS_TAXATION_INDIRECT_TAXATION,
        // },
        {
          id: 1078,
          name: "Capital Gains",
          href: URLs.FDI_BUSINESS_TAXATION_CAPITAL_GAINS,
        },
      ],
    },
    {
      id: 136,
      name: "Taxation of Expatriates",
      options: [
        { id: 10696, name: "Trends in India", href: URLs.FDI_TRENDS_IN_INDIA },
        {
          id: 10116,
          name: "Taxable Income of Expatriates",
          href: URLs.FDI_TAXABLE_INCOME_OF_EXPATRIATES,
        },
      ],
    },
    {
      id: 3852,
      name: "Corporate Compliance",
      options: [
        {
          id: 1785,
          name: "Statutory Requirements",
          href: URLs.FDI_STATUTORY_REQUIREMENT,
        },
        { id: 1705, name: "Tax Due Diligence", href: URLs.FDI_TAX_DUE_DILIGENCE },
      ],
    },
    {
      id: 3061,
      name: "Starting Business In India",
      options: [
        { id: 1150, name: "Overview", href: URLs.FDI_STARTING_BUSINESS_IN_INDIA },
        {
          id: 1190,
          name: "Account Outsourcing",
          href: URLs.FDI_ACCOUNT_OUTSOURCING,
        },
        {
          id: 1110,
          name: "Registration Of Foreign Companies",
          href: URLs.FDI_REGISTRATION_OF_FOREIGN_COMPANIES,
        },
      ],
    },
  ]

  return (
    <ul className={className + ""}>
      {/* <ul className={className + " overflow-y-scroll md:overflow-y-scroll"}> */}
      <li onClick={closeMobileModal}>
        <Link to={URLs.HOME} className={linkStyles}>
          Home
        </Link>
      </li>
      <li onClick={closeMobileModal}>
        <Link to={URLs.ABOUT_US} className={linkStyles}>
          About Us
        </Link>
      </li>
      {/* laptop screen */}
      <li className={linkStyles + " hidden md:block"}>
        <Popover className="">
          <Popover.Button className="inline-flex items-center gap-x-1 relative">
            <span>Services</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
              <div className="w-screen max-w-3xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4 grid md:grid-cols-3">
                  {services.map((item) => (
                    <div
                      key={item.id}
                      className="group relative p-4 text-theme-800 flex flex-col justify-start align-middle  gap-y-3 "
                    >
                      <p className="text-lg">{item.name}</p>
                      <div className="text-theme-400 flex flex-col justify-start align-middle text-md ">
                        {item.options.map((i) => (
                          <Link
                            to={i.href}
                            className="font-semibold hover:text-theme-800 "
                            key={i.id}
                          >
                            <Popover.Button className="text-left">
                              {i.name}
                            </Popover.Button>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </li>
      {/* mobile screen */}
      <li
        onClick={closeMobileModal}
        className={linkStyles + "overflow-auto block md:hidden"}
      >
        <Popover className="md:none relative">
          <Popover.Button className="inline-flex items-center gap-x-1 ">
            <span>Services</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="w-screen max-w-max overflow-auto text-sm mt-2">
              <div className="flex flex-col gap-y-3">
                {services.map((item) => (
                  <div
                    key={item.id}
                    className="group relative text-theme-400 flex flex-col gap-y-1 "
                  >
                    <p className="text-lg">{item.name}</p>
                    <div className="text-theme-200 flex flex-col">
                      {item.options.map((i) => (
                        <Link
                          onClick={closeMobileModal}
                          to={i.href}
                          className="font-semibold "
                          key={i.id}
                        >
                          {i.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </li>
      
      <li onClick={closeMobileModal}>
        <Link to={URLs.CLIENTS} className={linkStyles}>
          Clients
        </Link>
      </li>
      <li onClick={closeMobileModal}>
        <Link to={URLs.QUERIES} className={linkStyles}>
          Queries
        </Link>
      </li>

      <li onClick={closeMobileModal}>
        <Link to={URLs.CAREER} className={linkStyles}>
          Career
        </Link>
      </li>
      <li onClick={closeMobileModal}>
        <HashLink to={URLs.CONTACT_US} className={linkStyles}>
          Contact Us
        </HashLink>
      </li>
    </ul>
  )
}
