import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const DirectTaxation = () => {
  return (
    <PageLayout header={"DIRECT TAXATION"}>
      <div className="flex flex-col justify-between align-middle layout__header w-[100%]" >
        <div className="my-2">
          <h1 className={`my-2 ${h1}`}>Direct tax consultancy in India</h1>
          <p className="text-theme-800">Direct tax consultancy together with innovative tax efficient strategies, provided by us form an integral part of viable business decisions. These help our clients attain the desired goals. We adopt a “result oriented approach” which is flexible and emphasizes delivery and value. It enhances the effect of commercially viable decisions and minimizes the tax burden.</p>
          <h1 className={`my-2 ${h1}`}>Assessment (audit by tax authorities) and appeals</h1>
          <p className="text-theme-800">Every assessee, before submitting a tax return, is required to make a self-assessment of income and after taking into account the amount of tax already paid by way of TDS and advance tax, pays the balance tax (self-assessment tax) due on the income. Further, along with the tax, any interest arising on account of delay in furnishing of tax return or any default /deferment in payment of advance tax, is also required to be paid. The procedure for self-assessment and determination of tax liability has been depicted below by way of a flowchart.</p>
          <h1 className={`my-2 ${h1}`}>WITHHOLDING TAX</h1>
          <h1 className={`my-2 ${h1}`}>Withholding taxes on payments other than salaries</h1>
          <p className="text-theme-800">Under the Act, a person making certain specified payments such as salary, contractual payments, rent, interest, professional or technical fees, commission, payments to non-residents, etc. is required to withhold tax at source from such payments and comply with the associated requirements in respect of deposit of taxes in the Government treasury in the prescribed manner, issue of tax credit cerficates and filing withholding tax returns. The rationale of withholding tax is to provide a mechanism for tax authorities to collect tax at the source of income, and also to expand the tax base.</p>
          <p className="text-theme-800">Failure to comply with the prescribed withholding requirements could result in the levy of penal interest, penalty and prosecution under the Act, in addition to the liability to make up for the taxes not withheld/deposited.</p>
          <p className="my-1 text-theme-800">The following chart depicts the withholding tax provisions prescribed under the act:</p>

          <strong className="text-xs my-3">Some of the direct tax services provided by us include :</strong>
          <h1 className={`my-2 ${h1}`}>INCOME TAX & WITHHOLDING TAXES</h1>
          <ul className="list-disc my-">
            <li>Advising on Income Tax Planning of Corporates.</li>
            <li>Advising & reviewing of all necessary tax withholding responsibilities.</li>
            <li>Advising all financial subjects which are of your interest and keeping you updated on the new amendments, circulars, notifications & judgments.</li>
            <li>Verification of all payments to vendors for the purposes of determination of correct application of rates and category for deduction of withholding taxes.</li>
            <li>Computation of monthly TDS on the basis of above.</li>
            <li>Monthly tax reconciliation of the TDS due and deducted.</li>
            <li>Preparation and deposit of Monthly challans on or before the statutory due dates.</li>
            <li>Filing of quarterly E-TDS Returns.</li>
            <li>Filing annual Income Tax return.</li>
          </ul>
          <h1 className={`my-2 ${h1}`}>CERTIFICATION WORK</h1>
          <ul className="list-disc my-2">
            <li>Issuance of Chartered Accountant certificates u/s 195 required for the Overseas Remittances purposes from time to time.</li>
          </ul>
        </div>
      </div>
    </PageLayout>
  )
}

export default DirectTaxation