import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"

const AccountOutSourcing = () => {
  return (
    <PageLayout header={"ACCOUNTS OUTSOURCING"}>
    <div className="d-flex flex-col justify-start layout__header align-middle">
        <p className='my-2 text-theme-800'>Accounting Outsourcing Services in India</p>
        <div className="d-flex flex-col justify-center align-middle">
            <p className="my-2 text-theme-800"><strong>A refinance allows you </strong>to take out new personal loans that pay off your current mortgage. Although you are then obligated to make payments on the new top up card loan, your costs typically are lower after refinancing.</p>
            <p className="my-1 text-theme-800">In today’s scenario of globalization and technology convergence, the process of doing business has been redefined. Outsourcing various processes makes a lot of business sense. Most businesses and large companies are outsourcing their accounting processes for better management of their finances,and time etc.</p>
            <h1 className={`my-2 ${h1}`}>Book keeping and General Accounting Service</h1>
            <p className="text-theme-800">The service involves preparing and maintaining day to day bookkeeping and monthly or quarterly management accounts.These books are prepared as per the US GAAP accounting standards and can also be made as per specific client instructions</p>
            <h1 className={`my-2 ${h1}`}>Preparation of Financial Statements</h1>
            <p className="text-theme-800">The service involves preparing a company’s annual accounts and schedules ready for the statutory annual audit.</p>
            <h1 className={`my-2 ${h1}`}>Payroll</h1>
            <ul className="my-2 list-disc">
                <li>Preparation of the monthly payroll based on the inputs received from organization. The same would include all statutory and other relevant deductions as required.</li>
                <li>Ensure deductions of all relevant amounts and as per applicable statutory laws like Income Tax, provident Fund and Professional Tax etc.</li>
                <li>Checking Form 16 for the Employees and file Qtly. returns for e-TDS with Income Tax authorities.</li>
                <li>Deposit TDS & provide proof of deposit.</li>
                <li>Ensure redressal of any issues.</li>
                <li>Reconciliation of payments/statutory deductions etc. with books of accounts on quarterly basis.</li>
            </ul>
            <h1 className={`my-2 ${h1}`}>Tax planning & Returns</h1>
            <p className="text-theme-800">The service involves helping the clients make necessary periodic income tax, sales tax and other returns. It also includes helping the client make sound investment decisions and help him avail various rebates and deductions</p>
            <h1 className={`my-2 ${h1}`}>Cash Forecasting</h1>
            <p className="text-theme-800">The service involves analyzing the cash requirements of the business and making cash forecasts for planning the future.</p>
            <h1 className={`my-2 ${h1}`}>Advantages</h1>
            <ul className="my-2 list-disc">
                <li>Time saving</li>
                <li>Analyzing the potential risks in advance</li>
                <li>Correct interpretation of the company’s financial position</li>
                <li>Informed and better managerial decision making</li>
                <li>Collection, summarization, and accurate analysis of financial data</li>
                <li>Optimization of business resources and processes</li>
            </ul>
            </div>
            </div>
            </PageLayout>
  )
}

export default AccountOutSourcing