import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const CompanyFormationFAQs = () => {
  return (
    <PageLayout header={"Company Formation FAQs"}>
      <div className="flex flex-col justify-between align-middle layout__header w-[100%]" >
        <h1 className={`my-2 ${h1}`}>FAQ on Indian Companies</h1>
        <div className="flex flex-col justify-between align-middle">
          <h1 className={`my-2 ${h1}`}>What is a Private Limited Company?</h1>
          <p className="text-theme-800">A Private Limited Company is a Company limited by shares in which there can be maximum 200 shareholders, no invitation can be made to the public for subscription of shares or debentures, cannot make or accept deposits from Public and there are restriction on the transfer of shares.</p>
          <p className="text-theme-800">The liability of each shareholder is limited to the extent of the unpaid amount of the shares face value and the premium thereon in respect of the shares held by him. The minimum number of shareholders is 2</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What is a Public Limited Company?</h1>
          <p className="text-theme-800">A Public Limited Company is a Company limited by shares in which there is no restriction on the maximum number of shareholders, transfer of shares and acceptance of public deposits. The liability of each shareholder is limited to the extent of the unpaid amount of the shares face value and the premium thereon in respect of the shares held by him. The minimum number of shareholders is 7.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>Which entity is best suited?</h1>
          <p className="text-theme-800">The choice of entity depends on circumstance of each case. Private Limited Company has lesser number of compliance requirements. Therefore, generally where there is no requirement of raising of finances through a public issue and the ownership is intended to be closely held by limited number of persons, Private Limited Company is the best choice.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What is the minimum paid-up capital of a Private Limited Company?</h1>
          <p className="text-theme-800">The minimum paid up capital at the time of incorporation of a private limited company has to be Indian Rupees 1,00,000 (though it has been omitted by Companies Act 2013). There is no upper limit on having the authorized capital and the paid up capital. It can be increased any time, by payment of additional stamp duty and registration fee.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What is the difference between authorized capital and paid up capital?</h1>
          <p className="text-theme-800">The authorized capital is the capital limit authorized by the Registrar of Companies up to which the shares can be issued to the members / public, as the case may be. The paid up share capital is the paid portion of the capital subscribed by the shareholders.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What is the procedure in obtaining a name approval for the proposed Company?</h1>
          <p className="text-theme-800">An application in Form No. INC-1 needs to be filed with the Registrar of Companies (ROC) online through Digital Signature of one of the proposed director. The details to be furnished in the said application are as follows:</p>
          <ul className="list-disc my-2">
            <li>Alternative names for the proposed company. (The name can be coined names from the objects of the proposed company or the name of the directors, etc. but should definitely be indicative of the main object of the company. Justification for the name needs to be specified along with the application)</li>
            <li>Names and addresses of the promoters (Minimum 7 for a public company while 2 for private company).</li>
            <li>Authorized Capital of the proposed company.</li>
            <li>Main objects of the proposed company.</li>
            <li>Names of other group companies.</li>
          </ul>
          <p className="my-2 text-theme-800">On submitting the application, the ROC scrutinizes the same and sends the approval / objections in about 3-4 days to the applicant through e-mail.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What is the Memorandum Of Association (MOA) and the Articles Of Association (AOA) of a company and what is the procedure in this regard?</h1>
          <p className="text-theme-800">On receipt of the name approval intimation from the ROC the MOA and the AOA are required to be drafted. The MOA states the main, ancillary / subsidiary and other objects of the proposed company. The AOA contains the rules and procedures for the routine conduct of the proposed company. It also states the authorized share capital of the proposed company and the names of its first / permanent directors. After that MOA and AOA are required to be stamped.</p>
          <p className="text-theme-800">A stamp duty is required to be paid on MOA and AOA. The stamp duty depends on the authorized share capital.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What are the documents required to be executed for incorporation or Company Formation in India?</h1>
          <p className="text-theme-800">The following documents are required to be executed before they are submitted to the ROC:</p>
          <ul className="list-disc my-2">
            <li><strong>MOA and AOA –</strong> These are required to be signed by the promoters in their own handwriting in presence of a witness stating their</li>
            <li><strong>Form INC-7- –</strong> Twith incorporation of a new company (other than OPC).</li>
            <li><strong>Form INC-8 – –</strong>  – This is a declaration to be executed on a non-judicial stamp paper by one of the directors of the proposed company or other specified persons such as Attorneys or Chartered Accountant stating that all the requirements of the incorporation have been complied with.</li>
            <li><strong>Form INC <sub>22</sub> –</strong> This is a form to be filed by one of the directors of the company informing the ROC the registered office of the proposed company.</li>
            <li><strong>Form DIR-12 – –</strong>  This is a consent obtained from all the proposed directors of the proposed company to act as directors of the proposed company. (Not required in case of private company).</li>
            <li><strong>FForm DIR-2 –</strong> This is a form stating the fact of appointment of the proposed directors on the board of directors from the date of incorporation of the proposed company and is signed by one of the proposed directors. </li>
            <li><strong>INC-9-Declaration by first directors in case of new company –</strong> </li>
            <li>Power of Attorney signed by all the subscribers of MOA authorizing one of the subscribers or any other person to act on their behalf for the purpose of incorporation and accepting the certificate of incorporation.</li>
            <li>Filing fees as may be applicable.</li>
          </ul>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>How is the certificate of incorporation issued?</h1>
          <p className="text-theme-800">After the documents are filed, the ROC calls the attorney for scrutiny and making the corrections in the MOA and AOA filed. On complying with the same, the certificate of incorporation is issued.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>When can the newly formed company start its business operations?</h1>
          <p className="text-theme-800">On receipt of the certificate of incorporation, the public company has to complete certain other legal formalities such as a statutory meeting (within 6 months), statutory report, etc. On completion of the said formalities and on filing of the statutory report with the ROC the ROC issues the certificate of commencement of business to the company. Thereafter, the Public Company can start the business operations. The Private Company can start its business immediately on incorporation.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>How do we comply with the legal formalities when we are not stationed in India?</h1>
          <p className="text-theme-800">You can give Power of Attorney to a person to appear before ROC to complete the necessary formalities after getting MOA, AOA, Power of attorney and other allied documents notarised by notary public and attested by Indian Embassy/Consulate situated in foreign country.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>What other approvals are required for foreign investor in India?</h1>
          <p className="text-theme-800">Once the company is incorporated in India, foreign investor has to either intimate Reserve Bank of India (RBI) of the foreign equity or take approval of Foreign Investment Promotion Board (FIPB). Intimation to RBI or approval from FIPB is dependent upon sector in which foreign investor intends to do business.</p>
        </div>
        <div className="flex flex-col justify-between align-middle my-2">
          <h1 className={`my-2 ${h1}`}>How does a foreign company invest in India?</h1>
          <strong>Either through:-</strong>
          <p className="text-theme-800">1. Automatic Approval – by the country’s Central Bank, the Reserve Bank of India (RBI)</p>
          <p className="text-theme-800">Automatic Approval through Reserve Bank of India is available for all items/activities except a few as given in the Press Note No.4(2006series)</p>
          <p className="text-theme-800">No prior approval required. The company is only required to report to RBI within 30 days of receipt of foreign equity/allotment of shares.</p>
          <p className="text-theme-800">2. Foreign Investment Promotion Board (FIPB) approval is required for all other proposals not eligible for Automatic Approval.</p>
        </div>
      </div>
    </PageLayout>
  )
}

export default CompanyFormationFAQs
