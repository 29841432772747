import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import React from "react"

const containerStyle = {
  height: "400px",
}

const center = {
  lat: 28.985209800588326,
  lng: 77.01586705373886,
}

const CustomGoogleMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
      <Marker
        label="Sarash Jain & Associates"
        position={{ lat: 28.985209800588326, lng: 77.01586705373886 }}
      />
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(CustomGoogleMap)
