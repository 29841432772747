import React from "react"
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"

const TransferPricingFAQs = () => {
  return (
    <PageLayout header={"TRANSFER PRICING FAQ’s"}>
      <div className="flex flex-col justify-between align-middle mt-[15px] w-[100%]">
        <div className="flex flex-row  justify-around align-middle overview">
          <div className="flex flex-col justify-between align-middle px-4 w-[100%]">
            <h1 className={`my-2 ${h1}`}>Q1. When do the transfer pricing outline regulations apply to a business?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.Auditing Services When two or more associated companies enter into a mutual contract during an international transaction in order to apportion a particular cost incurred in relation with a benefit, service or facility offered by any one or all of the companies, such a cost shall be calculated considering the arm’s length price of the particular benefit, service, or facility, as applicable.</p>
            <h1 className={`my-2 ${h1}`}>Q2.When can two companies be called as ‘associated enterprises?’</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.According to sections 92, 92A, 92B, 92C, 92D, 92E and 92F, a company can be termed as an associated enterprise with respect to the other under the following circumstances. –
              If the respective company is involved directly or indirectly or with the help of one or more intermediaries in the management, control, or the capital of the other company.</p>
            <p className="text-theme-800">–
              If any person/persons of the respective company who is/are involved directly or indirectly or with the help of one or more intermediaries in the management, control, or the capital of one company is/are involved directly or indirectly or with the help of one or more intermediaries in the management, control, or the capital of the other company.</p>
            <h1 className={`my-2 ${h1}`}>. What is meant by ‘International Transaction’ with regard to Transfer Pricing Outline?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.An international Transaction is defined as any transaction between two or more associated companies situated in different countries in terms of a property that is tangible or intangible, a service offered by the company, or any form of lending of money, etc. It is compulsory that at least one of the participants involved in the transaction is a non-resident of India. However, a transaction that has been carried out by two non-resident Indians, where one of them possesses a permanent setup in India and whose income is taxable from India, such a type of transaction is also considered as ‘International Transaction.’
            </p>
            <h1 className={`my-2 text-theme-800 ${h1}`}>Q4. What are the different procedures to calculate the arm’s length price?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.The various procedures to calculate the arm’s length price with respect to an international transaction are the following.
            </p>
            <ul className="list-disc my-2">
              <li>Transactional net margin procedure</li>
              <li>Resale price procedure</li>
              <li>Comparable uncontrolled price procedure</li>
              <li>Cost plus procedure</li>
              <li>Profit split procedure</li>
            </ul>
            <h1 className={`my-2 ${h1}`}>Q5. What all documents are required to be maintained by a company while executing an international transaction?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.The following documents have to be maintained when a company is involved in an international transaction.
            </p>
            <ul className="list-disc my-2">
              <li>
                The details of the ownership of the person with respect to the
                company. These include the ownership structure, the details of the
                shares, and information on ownerships held by any other company on
                it.
              </li>
              <li>
                A detailed profile of the foreign group to which the assessed company
                is associated with for the international transactions. The details
                such as name, address, country where tax returns are filed, and the
                legal status, etc., have to be furnished about the multinational
                group.
              </li>
              <li>
                A detailed description of the business activities of both the
                assessed person and the associated group of companies with whom the
                former has been involved in international transaction.
              </li>
              <li>
                The details of the international transaction, such as the nature of
                the transaction, details of the property or services transferred, the
                terms contained in the transaction, and the amount and value of each
                transaction.
              </li>
              <li>
                The details of the functions carried out by such a transaction, the
                details of the risks involved and the value of the assets used or to
                be used by the assessed or the associated company that is involved in
                such a transaction.
              </li>
              <li>
                The details of the records collected for the entire business or a
                particular division of the business during the period of the
                company’s business activity in which the foreign transaction has been
                involved. These include reports such as the estimates made on various
                market trends, forecasts about the market, budget analysis or any
                other such finance-related reports prepared by the company.
              </li>
              <li>
                The details of the uncontrolled transactions, if any, that has taken
                place with a third party during the period of the international
                transaction. The nature and the terms and conditions of such
                transaction have to be mentioned as they play an important role in
                deciding the value of the international transaction.
              </li>
              <li>
                The details of the analysis conducted in order to assess the impact
                of the uncontrolled transaction on the international transaction
                concerned
              </li>
              <li>
                The details of the various procedures considered and the one adopted
                in deciding the arm’s length price with respect to an international
                transaction. The details should also include the details on why the
                particular method was adopted and how it was implemented successfully
                in order to decide the arm’s length price.
              </li>
            </ul>
            <h1 className={`my-2 ${h1}`}>
              Q6. Who is the authorized person to furnish the report under section
              92E of the Transfer Pricing Regulation Act?
            </h1>

            <p className='mb-2 text-theme-800'><strong>A</strong>.Any person who has involved in an international transaction in the previous year shall submit the report in Form 3CEB through a Chartered Accountant, duly verified by him, on or before the date prescribed by the authority, furnishing all the required details.
            </p>
            <strong className="my-2">
              Appropriate method- Can there be more than one?
            </strong>

            <h1 className={`my-2 ${h1}`}>
              Q7. Does the expression “arithmetical mean” warrant the inference that
              there could be two prices where the most appropriate method is
              followed?
            </h1>

            <p className='mb-2 text-theme-800'><strong>A</strong>.Since what is chosen is the “most appropriate method”, the concept of more than one appropriate price is a self-contradiction. But the proviso to section 92C(2) reads as under: “Provided that where more than one price may be determined by the most appropriate method, the arm’s length price shall be taken to be the arithmetical mean of such price.” The above proviso indicates that in the most appropriate method, which was chosen, there could be more than one price in which case the arithmetical mean will be taken. In other words, there could be more than one comparable and uncontrollable price, so that average could be adopted. Similarly, there could be more than one resale price or one cost plus price, so that the average can be adopted. The law does not, however contemplate more than one most appropriate method. Transfer pricing only when there is tax relevance
            </p>
            <h1 className={`my-2 ${h1}`}>Q8. Is the transfer pricing to be certified even when the assesses is not liable to income tax?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.An international transaction is one which takes place between a resident and a non resident or between two residents with a non resident associated concern as an intermediary. There is bound to be a tax impact for one or the other,so that transfer pricing become relevant , if not for the non- resident but atleast for the resident associate. Transfer pricing is bound to be relevant in such cases. It stands to reason, when the transfer pricing has no relevance at all for either party to the transaction, the rules would have no application, because the requirement of ascertainment of transfer pricing would not arise in such a case.
            </p>

            <strong className="my-2">Associate concern –Duration of association</strong>
            <h1 className={`my-2 ${h1}`}>Q9. An Indian company becomes associate of a non -resident in the last quarter of the previous year. Do the transfer pricing rules apply for the year? If it does, does it apply for the quarter or whole of the year?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.Transfer pricing rules relate to transactions. It is therefore reasonable to presume that the transaction covered in the last quarter of the previous year alone could be covered. There is possible view, that since it is an associate concern at any time during the year, all the transactions for the year are covered. The definition of “associated enterprise” in section 92A(2) would indicate, that an enterprise becomes an associate enterprise , if it becomes so “at any time during the previous year”. It would, therefore, mean that the associate enterprise is an associate enterprise for the whole year, so that the transaction for the period for which it was not associate enterprise may also be covered. This would, however, be a less plausible interpretation.
            </p>
            <strong className="my-2 text-theme-800">Related party transaction v Associated Enterprise</strong>

            <h1 className={`my-2 text-theme-800 ${h1}`}>Q10. Indian Accounting Standard 24 issued by the Institute of Chartered Accountant of India relates to related party transactions. What is its relevance to the concept of associated enterprise under transfer pricing rules? How does it affect gifts?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>Ind AS 24 would understand a related party transaction as a transfer of resources or obligation between related parties regardless of whether or not the price is charged. It would, therefore, appear that accounting standard 24 is even more plain and clear in comprehending a gift as one, which is covered by the transfer pricing rules, because a gift can be understood as transaction for which no price is charged.
            </p>
            <p className="text-theme-800">The Indian Accounting Standard 24 is styled “Related party disclosures” .The objectives is to make available relevant information in the financial statements in respect of accounting periods commencing from 1.4.2001, since it has become mandatory from that date. It is not applicable for intra- group transactions but it is applicable for related parties not forming part of intra- group either . Direct inter-party relationship or indirectly through controlled enterprise may come in for disclosure in the context of transaction between them.</p>
            <p className="text-theme-800">The concept of key management underlies the concept of control. Though key management is central to both the accounting standards and the transfer pricing rules, Ind AS 18 is more concerned with the disclosure of the transaction with a view to ensure transparency, and it is not concerned whether such transaction take place at arm’s length price, but only require information about such transactions to be disclosed. It is intended to help the user to appreciate the financial statements as to its operating results, its true financial position and net worth. It may help a person to understand the credit standing of the enterprise, resources by way of raw material or market, and such other information which may be relevant in judging the standing of the enterprise as a member or group of associated enterprise, since the normal reporting is one as an independent entity. Reports indicating common directors and the associated concerns would complete the required information for judging the standing of an enterprise.</p>
          </div>
          <div className="flex flex-col justify-start align-middle p-5  w-[100%]">
            <strong className="my-2">Confidentiality</strong>
            <h1 className={`my-2 ${h1}`}>Q11. International transactions largely require confidentiality more than in any domestic transaction. Is it open to auditor to insist upon such information? Can the assessing officer expect such information which is likely to be useful to the competitors?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.Confidentiality is a business requirement, which cannot be totally ignored. But then the overriding requirements of transparency has been recognized both by international Accounting Standards and Accounting Standards 18 which has now become mandatory. An exception, however, is made in respect of confidentiality protected by any statute or by any regulatory or competent authority. Only such of those private arrangements, the disclosure of which is violation of any such requirement will be safe.Income tax law does not place any limitation in this regard. But confidentiality is expected to be observed by income tax department, so that any information, that may be relevant for determination of arm’s length price, cannot be kept secret by a taxpayer on grounds of confidentiality.
            </p>
            <p className="text-theme-800">There have been assurances from the department, that the information that is given by the taxpayer would be kept confidential. Income tax law itself provide for secrecy. A public servant, who discloses any information except in public interest as covered by section 138 of income tax Act, is punishable with six months imprisonment and fine. An exception is made under section 287 of the Act, to permit publication of the names of persons and particulars of proceedings or prosecution in public interest. It would, therefore, appear that while disclosure requirements of reporting and auditing may be limited be confidentiality secured by law, there is no such limitations for the tax collector.</p>
            <strong className="my-2">What is auditor’s liability?</strong>
            <h1 className={`my-2 ${h1}`}>Q12 . There are a number of statutory audit reports, tax audit reports , reports of audit committees relating to corporate governance etc. It may be that the accounts or the transactions may be found to be in order with reference to the price without any adverse comment, while transfer pricing rules if applied to the accounts would indicate the accounts and the audit to be different. What are the consequences for the persons, who have reported and certified the accounts in such cases?</h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>. Reported accounts are based upon the actual transactions. Where there are contractual obligations, it is the contract price,that will be relevant. The fact the transaction may be between associated concerns does not dilute the character of such contractual obligation. Accounts are necessarily required on such basis. Transfer pricing rules on the other hand are concerned with determination of an independent price, which would have been the ruling price but for the association or influence of one enterprise over the other. Though the information may be common to a large extent, there is bound to be external information for judging the transfer price especially where the method adopted is “comparable uncontrolled price”. Even for other methods, the information other than what is germane to the enterprise, that is audited or which is subject matter of audit committee, would not fall within the preview. Even the mandatory Accounting Standard 18 is content with the requirements of reporting the associated or related transaction price does not necessarily mean that there is violation of any law. If, however, there are implications of such violations on the basis of information available in relation to transfer pricing rules, the reports as well as audit may be vulnerable unless such information was not available or would be irrelevant for the purposes of reporting and certification.
            </p>
            <strong className="">
              Prices approved by Government – Their relevance ?
            </strong>
            <h1 className={`my-2 ${h1}`}>
              Q13. Many of the international transactions are covered by agreements,
              which fix terms of remuneration consistent with industrial policy
              statement and often specially approved by Central Bank (Reserve bank of
              India in India) or the Central government. In such a case, can there be
              a different transfer pricing other than what is covered by other
              agreements?
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>. Where there is a government approval, whether by way of specific approval or because of general guidelines dispensing with such approval but all the same effective, it can be taken as a condition which governs the transfer price. Since the guidelines often fix a ceiling or a cap on the amount that is payable, such price need not be taken as sacrosanct, if there are clear circumstances to warrant that the transfer price can be different from the approved price. But where, as a condition such ceiling will materially affect the transfer price, it cannot be ignored.
            </p>
            <strong className="my-2">Maximum retail price – Their relevance</strong>
            <h1 className={`my-2 ${h1}`}>
              Q14. The law has fixed maximum retail price for certain business like
              pharmaceutical trade. Administrative prices are also fixed for most
              essential commodities known as civil supplies. In such a case, is it
              possible to infer a transfer price different from such fixed price?
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>. Answer to this query are same as the answer of preceding one. Such fixed price would be a condition, which May materially affect and control the market price, so that comparable uncontrolled price may not found. Administratively regulated, price would then be a material information for arriving at the transfer price different from such price is not ruled out
            </p>
            <strong className="my-2">
              Inter- branch transfer price- Their relevance
            </strong>
            <h1 className={`my-2 ${h1}`}>
              Q15. Do the transfer pricing rules apply in respect of transactions
              between head office and branch?
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.Head office and branch are one and the same in law. They are not an associate enterprise within the meaning of transfer pricing rules. But a branch may constitute itself as a permanent establishment, where it is located, so that the authorities would require the income attributable to the operations of such permanent establishment in the host country to be calculated. The principles under the transfer pricing rules would have application in such a case in determination of the income. But the partners may well expect the taxpayer to explain the basis adopted for accounting purposes. To the extent, that the principles adopted will have relevance to the transfer pricing rules, they cannot be ignored.
            </p>
            <strong className="my-2">Motive – Is it relevant?</strong>
            <h1 className={`my-2 ${h1}`}>
              Q16. Is the absence of a motive for adoption of transaction price at a
              lower or higher rate, a good defence against application of transfer
              pricing rules?
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.The presence or otherwise of any motive on the part of assessee to shift profits or profits by manipulations of prices need not be demonstrated by revenue. It was also decided, any objection to the analysis not raised before the Transfer Pricing Officer could not be taken before the tribunal in Dy CIT vs Deloitte Consulting India P.Ltd.[2012] 15 ITR (Trib) 573 (Hyd); SAP Labs India P. Ltd vs Asst. CIT[2012] 15 ITR (Trib)506 (bang).
            </p>
            <strong className="my-2">Capital gains – Relevance of Rules</strong>
            <h1 className={`my-2 ${h1}`}>
              Q17. Since the transfer pricing rules are targeted for determination of
              taxable income, could they have any relevance for determination of
              capital gains? Where shares in unlisted companies become the subject
              matter of a transaction as between associated enterprise, is it open to
              revenue to adopt transfer pricing rules for determination of capital
              gains?
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.
            </p>
            <strong className="my-2">What is auditor’s liability?</strong>
            <h1 className={`my-2 ${h1}`}>The presence or otherwise of any motive on the part of assessee to shift profits or profits by manipulations of prices need not be demonstrated by revenue. It was also decided, any objection to the analysis not raised before the Transfer Pricing Officer could not be taken before the tribunal in Dy CIT vs Deloitte Consulting India P.Ltd.[2012] 15 ITR (Trib) 573 (Hyd); SAP Labs India P. Ltd vs Asst. CIT[2012] 15 ITR (Trib)506 (bang).
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.Capital gains being a species of income, there is no escape from application of transfer pricing rules for determination of capital gains, so that an apparent consideration can be discredited if such transfer price as per rules is higher than transaction price.
            </p>
            <strong className="my-2">Certification – Its limitation</strong>
            <h1 className={`my-2 ${h1}`}>Q18. Is there any requirement for certification of transfer price in Form 3CEB, when there is no liability arising out of an international transaction between associated enterprise?
            </h1>
            <p className='mb-2 text-theme-800'><strong>A</strong>.A. If the transaction is between associated enterprise, transfer pricing rules may come into operation, if not in the hands of the non- residents as in the case of purchases made by it, but at least in the hands of the resident associate enterprise, so that in the latter case, such certification may be necessary. Where there is no income liable, the question of certification should not arise. Where there is no liability because the variation falls within the tolerance limit , it may require certification of for the purpose of verification as to whether the difference is within tolerance limit. In such a cases it may not be possible to avoid certification.
              It would be advisable for chartered accountants not to ignore the most appropriate method, where it is crying for acceptance and report on a totally inapplicable method, since the ultimate purpose of reporting is to arrive at arm’s length price, which is fair to both the taxpayer and revenue. In such a cases, he may advise the client not to persist in his mistake, as his choice of most appropriate method does not bind the assessing officer. If the certification were to ignore this basic requirement of confirming the correctness of choice of method by the taxpayer, it would not serve the purpose for which it is intended.
            </p>
          </div>
        </div></div>

    </PageLayout>
  )
}

export default TransferPricingFAQs
