// @ts-nocheck
import React from "react"
import { Link } from "react-router-dom"
import SocialLinks from "./SocialLinks"
// import Newsletter from "./newsletter"
import GoogleMap from "./GoogleMap"
import { newLogo } from "../assets"

function Footer({ className }) {
  return (
    <footer id="footer" className={className}>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="flex gap-5 flex-wrap md:flex-nowrap">
            <div className="min-w-[300px]">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label="Cruip">
                  <img src={newLogo} width="400" height="400" alt="logo" />
                </Link>
              </div>
              <div className="text-white">
                Head office: 655/23, Inderlok Gali, Near Old Court,
                <br /> Rohtak Road, Sonepat-131001, Haryana, India
                <br />
                <br />
                Branch office: E-52, Aastha Kunj, Sector-18, <br />
                Rohini-110089, Delhi, India
              </div>
            </div>

            <div
              style={{ width: "80%" }}
              className="flex-1 min-w-[300px] mt-5 h-full"
            >
              <GoogleMap />
            </div>
            {/* <div className="flex-1 min-w-[300px]">
              <Newsletter />
            </div> */}
          </div>

          {/* Bottom area */}
        </div>
      </div>
      <div className="bg-theme-100 h-25 md:h-10">
        <SocialLinks />
      </div>
      <div className="text-center py-2 text-white">
        © Sarash Jain & Associates | Chartered Accountants. All rights reserved.
      </div>
    </footer>
  )
}
export default Footer
